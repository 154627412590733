import React, { Component } from 'react'
// import { HashRouter as Router, Route } from 'react-router-dom'
import { Router } from '@reach/router'
import { enquireScreen } from 'enquire-js'

import Header from './pages/landing/Nav3'
import Footer from './pages/landing/Footer1'
import LandingHome from './pages/landing/index'
import Surveys from './pages/surveys/surveys'

import {
  Nav30DataSource,
  Footer10DataSource
} from './pages/landing/data.source'
import Dashboard from './pages/dashboard/dashboard'
import Diaries from './pages/dashboard/diaries'
import Specials from './pages/dashboard/specials'
import SpecialCategories from './pages/specials_categories'
import Login from './pages/dashboard/login'
import ResetPassword from './pages/dashboard/reset_password'
import PasswordReset from './pages/dashboard/password_reset'
import RequestAccess from './pages/dashboard/request_access'
import DiariesCategories from './pages/diaries_categories'
import './index.css'
import CaseStudyCategories from './pages/case_study_categories'
import CaseStudy from './pages/dashboard/case_study'
let isMobile;
enquireScreen(b => {
  isMobile = b
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile
    }
  }

  componentDidMount() {
    enquireScreen(b => {
      this.setState({ isMobile: !!b })
    })
  }

  render() {
    return (
      <div>
        <Header  dataSource={Nav30DataSource} isMobile={this.state.isMobile} style={{
          boxShadow: "5px 5px 10px",
          backgroundColor:"#ffffff",
          minHeight: "80px",
          lineHeight:"80px"
        }}/>

        <Router>
          <LandingHome path="/"/>
          <Surveys path="surveys"/>
          <Dashboard path={'dashboard'}/>
          <Diaries path={'diaries'}/>
          <CaseStudy path={'case-study'}/>
          <Specials path={'specials'}/>
          <DiariesCategories path={'diary-categories'}/>
          <SpecialCategories path={'special-categories'}/>
          <CaseStudyCategories path={'case-study-categories'}/>
          <Login path={'login'}/>
          <RequestAccess path={'request-access'}/>
          <ResetPassword path={'reset-password'}/>
          <PasswordReset path={'password-reset/:token'}/>
        </Router>
        <Footer
          dataSource={Footer10DataSource}
          isMobile={this.state.isMobile}
        />
      </div>
    )
  }
}

export default App
