import {Alert, Card, Row, Spin, Typography} from 'antd'
import React, {useEffect} from 'react'
import PieChart from 'highcharts-react-official'
import Highcharts from 'highcharts'
import useDataApi from '../../effects/fetcher'
import {BASE_URL, SURVEY_NO_SEGMENTATION_REPORT_URL, SURVEY_SEGMENTATION_REPORT_URL} from '../../environment/globals'

require('highcharts/modules/exporting')(Highcharts)

const {Meta} = Card
const {Title, Text} = Typography

function NoSegmentationReport({selectedQuestion, segmentationFilters, biweeklyFilters, isDiary = false, biweeklySpecial = false}) {

    // append parameter for diary questions
    const reportURL = isDiary ?
        '/api/repeat_response' :
        biweeklySpecial ?
            '/api/biweekly_response' :
            '/api/survey_line_responses'

    const [{isLoading, isError, error, data, forceUpdateSwitch}, setForceUpdateSwitch, setURL] =
        useDataApi(BASE_URL + reportURL + '?question_id=' + selectedQuestion.id + '&biweekly_filter[]=', [], {}, 'POST')

    useEffect(() => {

        // append parameter for diary questions
        // const special_question_parameter = isDiary ? 'is_diary_question=true' : biweeklySpecial ? 'is_biweekly_special_question=true' : ''

        if (selectedQuestion && selectedQuestion.id > 0) {

            if (segmentationFilters != null && segmentationFilters.length) {

                let url_encoded_filters = '';
                segmentationFilters.forEach((item, index) => {
                    url_encoded_filters += '&filters[]=' + item
                });
                if (biweeklyFilters != null && biweeklyFilters.length) {
                    biweeklyFilters.forEach((item, index) => {
                        url_encoded_filters += '&biweekly_filter[]=' + item
                    });
                }
                else {
                    url_encoded_filters += '&biweekly_filter[]='
                }

                setURL(BASE_URL + reportURL + '?question_id=' + selectedQuestion.id + url_encoded_filters)
            }
            else {
                let url_encoded_filters = '';
                if (biweeklyFilters != null && biweeklyFilters.length) {
                    biweeklyFilters.forEach((item, index) => {
                        url_encoded_filters += '&biweekly_filter[]=' + item
                    });
                }
                else {
                    url_encoded_filters += '&biweekly_filter[]='
                }
                setURL(BASE_URL + reportURL + '?question_id=' + selectedQuestion.id + url_encoded_filters)
            }
        }
    }, [setURL, selectedQuestion, segmentationFilters, biweeklyFilters, isDiary, biweeklySpecial]);
    const options = {
        chart: {
            type: 'pie'
        },
        credits: {
            enabled: false
        },
        title: {
            text: selectedQuestion.name || ''
        },
        colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
            '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a', '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
            '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
            '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
            '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
            '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
            '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
            '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
            '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
            '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
            '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'],
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.y} ({point.percentage:.1f}%)'
                },
                showInLegend: true
            }
        },
        series: [{
            name: selectedQuestion.name,
            colorByPoint: true,
            data: data.hasOwnProperty('series') ? data.series[0].hasOwnProperty('data') ? data.series[0]['data'] : [] : []
        }]
    }

    return (
        <Card style={{ marginTop: '2em' }} bordered={true} color={'blue'} bodyStyle={{ background: 'white' }}
              title={<Meta
            title={<Title type={'secondary'} level={4} style={{ marginBottom: 0 }}> Without Segmentation </Title>}
            description={'Visualize data not based on any segmentation'}
          />}>

            <Row type={'flex'} justify={'center'}>

                <Spin active="active" size={'large'} spinning={isLoading}>

                </Spin>
            </Row>

            {isLoading &&
            <Alert
                message={'Question did not load '}
                type='warning'
                description='No question is selected or the report could not be loaded'
                showIcon
            />
            }

            {!isLoading && selectedQuestion.id && (selectedQuestion.id > 0 ?
                <PieChart options={options} highcharts={Highcharts}/> :
                <Text type={'secondary'}> No data for numeric questions </Text>)}


        </Card>
    )
}

export default NoSegmentationReport