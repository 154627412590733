import {Card, Col, message, Row, Select, Tabs, Typography} from 'antd'
import React, {useState} from 'react'
import TopReports from './top_reports'
import CategoryQuestions from './category_questions'
import SegmentationFilters from './segmentations'
import SegmentationReport from './segmentation_report'
import NoSegmentationReport from './no_segmentation_report'
import './assets/main.css'
import AggregateQuestion from './aggregate_questions'
import useDataApi from '../../effects/fetcher'
import {BASE_URL, GET_BIWEEKLY_SPECIALS_URL} from '../../environment/globals'
import PointData from './point_data'

const {Title, Text} = Typography;

const {TabPane} = Tabs;

const {Option} = Select;

function Dashboard() {
    const [filterVisible, setFilterVisible] = useState(true);
    const [selectedQuestion, setSelectedQuestion] = useState({});
    const [segmentationQuestion, setSegmentationQuestion] = useState(null);
    const [segmentationFilters, setSegmentationFilters] = useState([]);
    const [aggregateData, setAggregateData] = useState({questions: [], computation: 1});

    const [{isLoading, isError, error, data, forceUpdateSwitch}, setForceUpdateSwitch] =
        useDataApi(BASE_URL + GET_BIWEEKLY_SPECIALS_URL, ['{}, {}, {}, {}, {}']);

    const [isDiary] = useState(sessionStorage.getItem('isDiary') === 'true');

    const [biweeklySpecial] = useState(sessionStorage.getItem('biweekly_specials') === 'true');


    const switchFilter = (value) => {
        setFilterVisible(value)
    };
    const questionSelected = (question) => {
        setSelectedQuestion(question)
    };
    const segmentationQuestionSelected = (question) => {
        setSegmentationQuestion(question)
    };

    const filterSegmentations = (filters) => {
        if (filters.length !== segmentationFilters.length) {
            setSegmentationFilters(filters)
        } else {
            filters.forEach((item, index) => {
                if (item !== segmentationFilters[index]) {

                    setSegmentationFilters(filters)
                }
            })
        }
    };


    const calculateAggregate = (questions, computation) => {
        if (questions.length >= 2) {

            let firstType = questions[0].type;
            let collision = false;
            questions.forEach(question => {
                if (question.type !== firstType) {
                    collision = true
                }
            });
            if (collision) {
                message.error('Make sure the selected questions have the same type!')
            } else {
                questionSelected({
                    name: `${questions.length} numeric questions selected`,
                    short_form: `${questions.length} numeric questions selected`,
                    id: -1,
                    parent: 0
                });
                setAggregateData({
                    questions: questions.map(question => question.id),
                    computation: computation
                })
            }

        } else {
            message.warning('Make sure 2 more or numeric questions are selected!')
        }
    };
    return (
        <div>
            <Row type={'flex'} justify={'space-between'} style={{ paddingTop: '2em', marginBottom: '2em' }}>
                <Col span={12} style={{ paddingLeft: '2em', color: 'white' }}>

                    <Text level={4} secondary="true" style={{ color: '#e9e9e9' }}> Dashboard </Text>
                    <br/>
                    <Title level={2}
                           style={{
                   marginTop: 0,
                   color: '#e9e9e9'
                 }}> {sessionStorage.getItem('surveyName', '')} Overview </Title>

                </Col>
                {
                    biweeklySpecial &&
                    <Col span={12}>
                        <Card bordered={false}>

                            <Text level={4} secondary="true" style={{ display: 'block' }}>
                                Select a Biweekly Special </Text>
                            <Select loading={isLoading} size={'large'}
                                    defaultValue={sessionStorage.getItem('selectedBiweeklySpecial')}
                                    placeholder={'Select a segmentation question'}
                                    style={{ width: '20rem' }}

                                    onChange={(biweeklySpecial) => {
                        sessionStorage.setItem('surveyId', biweeklySpecial.id);
                        sessionStorage.setItem('selectedBiweeklySpecial', biweeklySpecial.name);
                        sessionStorage.setItem('isDiary', false);
                        sessionStorage.setItem('surveyName', biweeklySpecial.name);
                        window.location.href = '/dashboard'
                      }}
                            >
                                {
                                    data.map((biweeklySpecial, index) => {
                                        return <Option
                                            key={biweeklySpecial.id}
                                            title={biweeklySpecial.name}
                                            value={biweeklySpecial}


                                        >
                                            {biweeklySpecial.name}
                                        </Option>
                                    })
                                }
                            </Select>
                        </Card>
                    </Col>
                }
                {/*<Col span={24}>*/}
                    {/*<TopReports/>*/}
                {/*</Col>*/}
            </Row>
            <Row type={'flex'} justify={'space-between'}>
                <Col span={6}>
                    <Tabs defaultActiveKey={"1"}>

                        <TabPane tab={<span>Normal Questions</span>} key={1}>
                            <CategoryQuestions
                                questionSelected={questionSelected}
                                isDiary={isDiary}
                            />
                        </TabPane>
                        <TabPane tab={<span>Aggregate Questions</span>} key={2}>
                            <AggregateQuestion
                                questionSelected={questionSelected}
                                calculateAggregate={calculateAggregate}
                                isDiary={isDiary}
                            />
                        </TabPane>

                    </Tabs>
                </Col>

                <Col span={18}>
                    <Row type={'flex'} justify={'space-between'} style={{ margin: '3em' }}>
                        <Col span={filterVisible ? 16 : 22}>

                            <Tabs defaultActiveKey={"1"} type={'card'} animated={true}>
                                <TabPane
                                    tab={<span style={{ fontSize: '1.1em', padding: '3em 4em', fontWeight: 'bold' }}>Segmentation Report</span>}
                                    key={1}>
                                    {<SegmentationReport selectedQuestion={selectedQuestion}
                                                         segmentationQuestion={segmentationQuestion}
                                                         segmentationFilters={segmentationFilters}
                                                         aggregateData={aggregateData}
                                                         questionSelected={questionSelected}
                                                         isDiary={false}
                                                         biweeklySpecial={false}

                                    />}
                                </TabPane>
                                {selectedQuestion && selectedQuestion.type !== "Multiple" &&
                                <TabPane
                                    tab={<span
                        style={{
                          fontSize: '1.1em',
                          padding: '2em 4em',
                          fontWeight: 'bold'
                        }}>No Segmentation Report</span>}
                                    key={2}>
                                    {
                                        <NoSegmentationReport
                                            selectedQuestion={selectedQuestion}
                                            segmentationFilters={segmentationFilters}
                                            isDiary={false}
                                            biweeklySpecial={false}
                                        />
                                    }
                                </TabPane>
                                }
                            </Tabs>
                            {
                                selectedQuestion && (selectedQuestion.type === "Numeric" || selectedQuestion.type === "Money") &&
                            <PointData selectedQuestion={selectedQuestion}
                                       segmentationQuestion={segmentationQuestion}
                                       isDiary={false}
                                       biweeklySpecial={false}/>
                            }


                        </Col>
                        <Col span={filterVisible ? 8 : 2}>

                            {<SegmentationFilters switchFilter={switchFilter}
                                                  segmentationQuestionSelected={segmentationQuestionSelected}
                                                  filterSegmentations={filterSegmentations}
                                                  isDiary={false}
                                                  biweeklySpecial={false}

                            />}
                        </Col>

                    </Row>
                </Col>

            </Row>


        </div>
    )
}

export default Dashboard;