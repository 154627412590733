import {Component} from 'react';

class CustomComponent extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState(obj, callback) {
    if (this._isMounted) {
        this.setState(obj, callback);
    }
  }
}

export default CustomComponent;
