import axios from "axios";
import {GET_COOKIE} from '../environment/globals'

const BASEURL = "https://riseapi.l-ift.com";
// const BASEURL = "http://localhost:4000";

export default axios.create({
  baseURL: BASEURL,
  responseType: "json",
  headers: {
    'Content-Type': 'application/json',
    Authorization: {
        toString () {
          return `Bearer ${GET_COOKIE('access_token')}`
        }
      }
  },
});
