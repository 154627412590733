import {Card, Col, Divider, Empty, message, Row, Select, Skeleton, Statistic, Tabs, Typography} from 'antd'

import React, {useState} from 'react'
import CustomComponent from '../../CustomComponent'
import TopReports from './top_reports'
import CategoryQuestions from './category_questions'
import SegmentationFilters from './segmentations'
import SegmentationReport from './segmentation_report'
import NoSegmentationReport from './no_segmentation_report'
import './assets/main.css'
import AggregateQuestion from './aggregate_questions'
import API from '../../effects/API'
import {BASE_URL, GET_BIWEEKLY_SPECIALS_URL, GET_QUICK_INFOS} from '../../environment/globals'
import PointData from './point_data'
import { faKaaba } from '@fortawesome/free-solid-svg-icons'

const {Title, Text} = Typography;

const {TabPane} = Tabs;

const {Option} = Select;
const {Meta} = Card;

function getFormattedNumber(number) {
    const fixedNumber = Number.parseFloat(number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class QuickInfo extends CustomComponent {
    constructor(props) {
        super(props);
        const CATEGORY_COLORS = [
            '#F95700FF',
            '#00539CFF',
            '#2C5F2DFF',
            '#D85A7FFF',
            '#603F83FF',
            '#aa2761FF',
            '#2BAE66FF',
            '#9d9100FF',
            '#F2AA4CFF',
            '#2C5F2DFF',
            '#41a1f2FF',
            '#D85A7FFF',
            '#00539CFF',
            '#603F83FF',
            '#F2AA4CFF',
            '#2098a2FF',
            '#603F83FF',
            '#7b31cdFF'];
        this.state = {
            index: props.index,
            primary: props.info.primary,
            secondary: props.info.secondary,
            number: props.info.number,
            color: CATEGORY_COLORS[props.index % CATEGORY_COLORS.length],
            info: props.info
        };
    }
    
    componentWillUpdate(props, state)
    {
        if (this.state.number != props.info.number) {
            this.updateState({
                primary: props.info.primary, 
                secondary: props.info.secondary,
                number: props.info.number, 
                index: props.index,
                info: props.info
            });
        }
    }

    render() {
        return (
            <Col xs={24} md={12} lg={8} className={'quick-info surveys mid-padded'}>
                <Card style={{
                borderWidth: 0}} hoverable={true}
                      bodyStyle={{ border: "solid", borderColor: this.state.color, borderWidth: 2 }}>

                    <Skeleton loading={false} active={true}>
                        <Divider>
                            <Meta
                                title={<h2 className="surveys text-center" style={{ backgroundColor: this.state.color, 
                                    color: "white", padding:"3px", paddingLeft: "20px", 
                                    paddingRight:"20px", borderRadius: 25 }}>{this.state.primary}</h2>}
                                style={{ margin: 0, color: this.state.color }}
                                description={<span style={{ color: this.state.color }}></span>}
                            />
                        </Divider>
                        <Row type="flex" justify="space-around">

                            <Statistic valueStyle={{ textAlign: 'center', color: this.state.color }}
                                       title={<span style={{ textAlign: 'center', color: this.state.color }}> {this.state.secondary} </span>}
                                       value={this.state.number}/>

                        </Row>
                    </Skeleton>
                </Card>

            </Col>
        )
    }
}

class Diaries extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            filterVisible: true,
            selectedQuestion: {},
            segmentationQuestion: null,
            segmentationFilters: [],
            biweeklyFilters: [],
            aggregateData: {questions: [], computation: 1},
            isLoading: false,
            isError: false,
            categoryId: sessionStorage.getItem('surveyId'),
            isDiary: true,
            biweeklySpecial: sessionStorage.getItem('biweekly_specials') === 'true',
            error: '',
            data: null,
            quick_infos: [
                
            ]
        };

        this.switchFilter = this.switchFilter.bind(this);
        this.questionSelected = this.questionSelected.bind(this);
        this.segmentationQuestionSelected = this.segmentationQuestionSelected.bind(this);
        this.filterSegmentations = this.filterSegmentations.bind(this);
        this.calculateAggregate = this.calculateAggregate.bind(this);
    }

    componentDidMount()
    {
        super.componentDidMount();
        this.getQuickInfos();
    }

    componentWillUpdate(nextProps, nextState)
    {
        let call = false;
        if (JSON.stringify(this.state.biweeklyFilters) != JSON.stringify(nextState.biweeklyFilters)) {
            call = true;
        }
        if (JSON.stringify(this.state.segmentationFilters) != JSON.stringify(nextState.segmentationFilters)) {
            call = true;
        }
        if (call)
        {
            this.setState({call: call});
        }
    }

    componentDidUpdate() {
        if (this.state.call) {

            this.setState({call: false});
            this.getQuickInfos();
        }
    }

    getQuickInfos()
    {
        this.updateState({
            isError: false,
            isLoading: true
        });
        let questions = [];
        if (this.state.categoryId == 3)
        {
            questions = [1201, 1205]
        }
        else if (this.state.categoryId == 4)
        {
            questions = [1201, 1209]
        }
        else if (this.state.categoryId == 5)
        {
            questions = [1201, 1213]
        }
        else if (this.state.categoryId == 6)
        {
            questions = [1201, 1217]
        }
        else if (this.state.categoryId == 9)
        {
            questions = [1233]
        }
        else if (this.state.categoryId == 10)
        {
            questions = [1221, 1223]
        }
        else if (this.state.categoryId == 12)
        {
            questions = [1227, 1229]
        }
        const data = {
            questions: questions,
            biweekly_filter: this.state.biweeklyFilters,
            filters: this.state.segmentationFilters,
        };
        API.post(GET_QUICK_INFOS,data).then(res => {
            const infos = [];
            for (let i = 0; i < data.questions.length; i++)
            {
                const questId = data.questions[i];
                if (questId == 1201)
                {
                    infos.push({
                        "primary": "Total Income",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['total']),
                    })
                    infos.push({
                        "primary": "Average Income",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['average']),
                    })
                    // infos.push({
                    //     "primary": "Maximum Income",
                    //     "secondary": "",
                    //     "number": "$" + getFormattedNumber(res.data[questId]['maximum']),
                    // })
                }
                else if (questId == 1205)
                {
                    infos.push({
                        "primary": "Total Business Income",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['total'], 0),
                    })
                    infos.push({
                        "primary": "Average Business Income",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['average'], 0),
                    })
                    // infos.push({
                    //     "primary": "Maximum Business Income",
                    //     "secondary": "",
                    //     "number": "$" + getFormattedNumber(res.data[questId]['maximum'], 0),
                    // })
                }
                else if (questId == 1213)
                {
                    infos.push({
                        "primary": "Total Agriculture Income",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['total'], 0),
                    })
                    infos.push({
                        "primary": "Average Agriculture Income",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['average'], 0),
                    })
                    // infos.push({
                    //     "primary": "Maximum Agriculture Income",
                    //     "secondary": "",
                    //     "number": "$" + getFormattedNumber(res.data[questId]['maximum'], 0),
                    // })
                }
                else if (questId == 1209)
                {
                    infos.push({
                        "primary": "Total Employment Income",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['total'], 0),
                    })
                    infos.push({
                        "primary": "Average Employment Income",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['average'], 0),
                    })
                    // infos.push({
                    //     "primary": "Maximum Employment Income",
                    //     "secondary": "",
                    //     "number": "$" + getFormattedNumber(res.data[questId]['maximum'], 0),
                    // })
                }
                else if (questId == 1217)
                {
                    infos.push({
                        "primary": "Total Non-Work Income",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['total'], 0),
                    })
                    infos.push({
                        "primary": "Average Non-Work Income",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['average'], 0),
                    })
                    // infos.push({
                    //     "primary": "Maximum Non-Work Income",
                    //     "secondary": "",
                    //     "number": "$" + getFormattedNumber(res.data[questId]['maximum'], 0),
                    // })
                }
                else if (questId == 1233)
                {
                    infos.push({
                        "primary": "Total Expenditure",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['total'], 0),
                    })
                    infos.push({
                        "primary": "Average Expenditure",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['average'], 0),
                    })
                    // infos.push({
                    //     "primary": "Maximum Expenditure",
                    //     "secondary": "",
                    //     "number": "$" + getFormattedNumber(res.data[questId]['maximum'], 0),
                    // })
                }
                else if (questId == 1221)
                {
                    infos.push({
                        "primary": "Total Savings Deposited",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['total'], 0),
                    })
                    infos.push({
                        "primary": "Average Savings Deposited",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['average'], 0),
                    })
                    // infos.push({
                    //     "primary": "Maximum Savings Deposited",
                    //     "secondary": "",
                    //     "number": "$" + getFormattedNumber(res.data[questId]['maximum'], 0),
                    // })
                }
                else if (questId == 1223)
                {
                    infos.push({
                        "primary": "Total Savings Withdrawn",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['total'], 0),
                    })
                    infos.push({
                        "primary": "Average Savings Withdrawn",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['average'], 0),
                    })
                    // infos.push({
                    //     "primary": "Maximum Savings Withdrawn",
                    //     "secondary": "",
                    //     "number": "$" + getFormattedNumber(res.data[questId]['maximum'], 0),
                    // })
                }
                else if (questId == 1227)
                {
                    infos.push({
                        "primary": "Total Loans Taken",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['total'], 0),
                    })
                    infos.push({
                        "primary": "Average Loans Taken",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['average'], 0),
                    })
                    // infos.push({
                    //     "primary": "Maximum Loans Taken",
                    //     "secondary": "",
                    //     "number": "$" + getFormattedNumber(res.data[questId]['maximum'], 0),
                    // })
                }
                else if (questId == 1229)
                {
                    infos.push({
                        "primary": "Total Loans Paid Back",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['total'], 0),
                    })
                    infos.push({
                        "primary": "Average Loans Paid Back",
                        "secondary": "",
                        "number": "$" + getFormattedNumber(res.data[questId]['average'], 0),
                    })
                    // infos.push({
                    //     "primary": "Maximum Loans Paid Back",
                    //     "secondary": "",
                    //     "number": "$" + getFormattedNumber(res.data[questId]['maximum'], 0),
                    // })
                }
            }
            this.updateState({
                quick_infos: infos,
                isLoading: false
            });
        }).catch(err => {
            this.updateState({
                isError: true,
                error: err
            });
        });
    }

    switchFilter = (value) => {
        this.updateState({
            filterVisible: value
        })
    };
    questionSelected = (question) => {
        this.updateState({
            selectedQuestion:question
        })
    };

    segmentationQuestionSelected = (question) => {
        this.updateState({
            segmentationQuestion: question
        })
    };

    filterSegmentations(filters, biweeklyFilters) {
        if (filters.length !== this.state.segmentationFilters.length) {
            this.updateState({
                segmentationFilters:filters
            });
        } else {
            filters.forEach((item, index) => {
                if (item !== this.state.segmentationFilters[index]) {

                    this.updateState({
                        segmentationFilters:filters
                    });
                }
            });
        }
        this.updateState({
            biweeklyFilters:biweeklyFilters
        });
    };

    calculateAggregate(questions, computation) {
        if (questions.length >= 2) {

            let firstType = questions[0].type;
            let collision = false;
            questions.forEach(question => {
                if (question.type !== firstType) {
                    collision = true
                }
            });
            if (collision) {
                message.error('Make sure the selected questions have the same type!')
            } else {
                this.questionSelected({
                    name: `${questions.length} questions selected`,
                    short_form: `${questions.length} questions selected`,
                    id: -1,
                    parent: 0
                });
                this.updateState({
                    aggregateData: {
                        questions: questions.map(question => question.id),
                        computation: computation
                    }
                })
            }

        } else {
            message.warning('Make sure 2 more or numeric questions are selected!')
        }
    };

    render() {
        return (
            <div>
                <Row type={'flex'} justify={'space-between'} style={{ paddingTop: '2em', marginBottom: '2em' }}>
                    <Col span={12} style={{ paddingLeft: '2em', color: 'white' }}>

                        <Text level={4} secondary="true" style={{ color: '#e9e9e9' }}> Dashboard </Text>
                        <br/>
                        <Title level={2}
                               style={{
                   marginTop: 0,
                   color: '#e9e9e9'
                 }}> {sessionStorage.getItem('surveyName', '')} Overview </Title>

                    </Col>
                </Row>
                {
                    this.state.quick_infos.length > 0 && 
                    <Row type={'flex'} justify={'space-between'}>
                        <Col span={12} style={{ paddingLeft: '2em', color: 'black' }}>
                            <h2>Quick Information</h2>
                        </Col>
                    </Row>
                }
                {
                    this.state.quick_infos.length > 0 && 
                    <Row type={'flex'} justify={'space-between'} style={{ paddingLeft: '2em', color: 'black' }}>
                        {
                            this.state.quick_infos.map((info, index) => {
                                return (
                                    <QuickInfo key={index} info={info} index={index}/>
                                )
                            })
                        }
                    </Row>
                }
                
                <Row type={'flex'} justify={'space-between'}>
                    <Col span={6}>
                        <Tabs defaultActiveKey={"1"}>
                            { this.state.categoryId && this.state.categoryId > 0 &&
                                <TabPane tab={<span>Direct Questions</span>} key={1}>
                                    <CategoryQuestions
                                        questionSelected={this.questionSelected}
                                        isDiary={this.state.isDiary}
                                    />
                                </TabPane>
                            }
                            <TabPane tab={<span>Aggregate Questions</span>} key={2}>
                                <AggregateQuestion
                                    questionSelected={this.questionSelected}
                                    calculateAggregate={this.calculateAggregate}
                                    isDiary={this.state.isDiary}
                                />
                            </TabPane>

                        </Tabs>
                    </Col>

                    <Col span={18}>
                        <Row type={'flex'} justify={'space-between'} style={{ margin: '3em' }}>
                            <Col span={this.state.filterVisible ? 16 : 22}>

                                <Tabs defaultActiveKey={"1"} type={'card'} animated={true}>
                                    <TabPane
                                        tab={<span style={{ fontSize: '1.1em', padding: '3em 4em', fontWeight: 'bold' }}>Segmentation Report</span>}
                                        key={1}>
                                        {<SegmentationReport selectedQuestion={this.state.selectedQuestion}
                                                             segmentationQuestion={this.state.segmentationQuestion}
                                                             segmentationFilters={this.state.segmentationFilters}
                                                             biweeklyFilters={this.state.biweeklyFilters}
                                                             aggregateData={this.state.aggregateData}
                                                             questionSelected={this.questionSelected}
                                                             isDiary={this.state.isDiary}
                                                             biweeklySpecial={this.state.biweeklySpecial}

                                        />}
                                    </TabPane>
                                    {this.state.selectedQuestion && this.state.selectedQuestion.type !== "Multiple" &&
                                    <TabPane
                                        tab={<span
                        style={{
                          fontSize: '1.1em',
                          padding: '2em 4em',
                          fontWeight: 'bold'
                        }}>No Segmentation Report</span>}
                                        key={2}>
                                        {
                                            <NoSegmentationReport
                                                selectedQuestion={this.state.selectedQuestion}
                                                segmentationFilters={this.state.segmentationFilters}
                                                biweeklyFilters={this.state.biweeklyFilters}
                                                isDiary={this.state.isDiary}
                                                biweeklySpecial={this.state.biweeklySpecial}
                                            />
                                        }
                                    </TabPane>
                                    }
                                </Tabs>
                                {
                                    this.state.selectedQuestion && (this.state.selectedQuestion.type === "Numeric" || this.state.selectedQuestion.type === "Money") &&
                                <PointData selectedQuestion={this.state.selectedQuestion}
                                           segmentationQuestion={this.state.segmentationQuestion}
                                           isDiary={this.state.isDiary}
                                           biweeklySpecial={this.state.biweeklySpecial}/>
                                }


                            </Col>
                            <Col span={this.state.filterVisible ? 8 : 2}>

                                {<SegmentationFilters switchFilter={this.switchFilter}
                                                      segmentationQuestionSelected={this.segmentationQuestionSelected}
                                                      filterSegmentations={this.filterSegmentations}
                                                      isDiary={true}
                                                      biweeklySpecial={false}

                                />}
                            </Col>

                        </Row>
                    </Col>

                </Row>
            </div>
        )
    }
}
export default Diaries;