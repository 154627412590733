import useDataApi from '../../effects/fetcher'
import {BASE_URL, SURVEY_SEGMENTATIONS_URL} from '../../environment/globals'
import API from '../../effects/API'
import {
    Alert,
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Empty,
    Icon,
    Row,
    Select,
    Skeleton,
    Spin,
    Switch,
    Typography
} from 'antd'
import RetryHomeRow from '../../ui_pieces/retry_home_row'
import React, {useEffect, useState} from 'react'

const {Title} = Typography;
const {Option} = Select;

function SegmentationFilters({switchFilter, segmentationQuestionSelected, filterSegmentations, isDiary = false, biweeklySpecial = false, isCaseStudy=false}) {

    const segmentationQuestionsURL = isDiary || biweeklySpecial ?
        '/api/diaries/segmentations' :
        '/api/biweeklies/' + sessionStorage.getItem('surveyId') + '/segmentation-questions';

    const segmentationFiltersURL = '/api/diaries/segmentation-filters';
    const biweekliesListUrl = '/api/biweeklies?filter=true';

    const [{isLoading, isError, error, data, forceUpdateSwitch}, setForceUpdateSwitch] =
        useDataApi(BASE_URL + segmentationFiltersURL, []);

    const [{isLoading: segQuesIsLoading, isError: segQuesIsError, error: segQuesError, data: segQuesData, forceUpdateSwitch: segQiesforceUpdateSwitch},
        segQuesSetForceUpdateSwitch, segQuesSetURL] = useDataApi(BASE_URL + segmentationQuestionsURL, {biweekly_questions: []});

    const checkedValues = [];
    const [selectedFilters, setSelectedFilters] = useState({});
    const [selectedBiweeklies, setSelectedBiweeklies] = useState([]);

    const [visible, setVisible] = useState(true);
    const [biweeklies, setBiweeklies] = useState([]);
    const [isBiweeklyLoading, setBiweeklyLoading] = useState(false);

    let segmentationFilters = null;
    let segmentationQuestionData = null;
    let handleFilterChanged = (values, groupName) => {
        let newSelectedFilters = selectedFilters;

        newSelectedFilters[groupName] = values;
        setSelectedFilters(newSelectedFilters);
    };
    let handleBiweeklyFilterChanged = (value) => {
        let newSelectedBiweeklies = value;

        setSelectedBiweeklies(newSelectedBiweeklies);
    };

    let handleFilterSegmentations = () => {
        let filters = [];
        for (const filter in selectedFilters) {
            if (selectedFilters.hasOwnProperty(filter)) {
                selectedFilters[filter].forEach((item, index) => {
                    filters.push(item)
                })
            }
        }
        filterSegmentations(filters, selectedBiweeklies);
    };

    let handleSwitchChanged = (value) => {
        setVisible(value);
        switchFilter(value)
    };

    if (isDiary && !isCaseStudy && !isBiweeklyLoading) {
        setBiweeklyLoading(true);
        API.get(BASE_URL + biweekliesListUrl).then(res => {
            setBiweeklies(res.data.biweeklies);
        }).catch(err => {
        });
    }

    useEffect(() => {
        // setSelectedFilters(checkedValues)
    }, []);
    useEffect(() => {
        if (segQuesData.biweekly_questions && segQuesData.biweekly_questions.length) {
            segmentationQuestionSelected(segQuesData.biweekly_questions[0].id)
        } else if (segQuesData.length) {
            segmentationQuestionSelected(segQuesData[0].id)
        }
    }, [segQuesData]);

    try {
        if (segQuesData.length) {
            if (isDiary && !isCaseStudy && segQuesData[segQuesData.length - 1].id !== "biweekly") {
                segQuesData.push({
                    id: "biweekly",
                    name: "Biweekly",
                    column_name: "biweekly",
                    values: []
                });
            }
        }
        if (data.length) {
            if (isDiary && !isCaseStudy && data[data.length - 1].id !== "biweekly") {
                data.push({
                    id: "biweekly",
                    name: "Biweekly",
                    column_name: "biweekly",
                    values: biweeklies
                });
            }
            else if(!isCaseStudy && isDiary) {
                data[data.length - 1].values = biweeklies;
            }
            data.forEach(datum => {
                datum.values.forEach(segmentationValue => {
                    checkedValues.push(segmentationValue.id)
                })
            })
        }

        segmentationFilters = data.length ?
            data.map((segmentation, index) => {
                const options = [];

                if (segmentation.id === "biweekly") {
                    if (segmentation.hasOwnProperty('values')) {
                        segmentation.values.forEach((segmentationValue, index) => {
                                options.push({label: segmentationValue.name, value: segmentationValue.id});
                            }
                        )
                    }
                }
                else {
                    if (segmentation.hasOwnProperty('values')) {
                        segmentation.values.forEach((segmentationValue, index) =>
                            options.push({label: segmentationValue.value, value: segmentationValue.id})
                        )
                    }
                }

                return (
                    <Col span={24} key={index} style={{ marginBottom: '1em' }}>

                        <Card loading={isLoading} style={{ margin: '.4em' }} bodyStyle={{ padding: '.3em' }}
                              bordered={false}
                        >
                            <Title level={4} type={'secondary'}> {segmentation.name} </Title>
                            {segmentation.id === "biweekly" &&
                            <Checkbox.Group options={options}
                                // defaultValue={checkedValues}
                                            onChange={(data) => handleBiweeklyFilterChanged(data, segmentation.id)}
                            />
                            }
                            {segmentation.id !== "biweekly" &&
                            <Checkbox.Group options={options}
                                // defaultValue={checkedValues}
                                            onChange={(data) => handleFilterChanged(data, segmentation.name)}
                            />
                            }
                        </Card>
                    </Col>
                )
            })
            : <Row type={'flex'} justify={'center'}>
                <Empty description={'No segmentation could be found, try again'}/>
                <RetryHomeRow
                    retryHandler={() => setForceUpdateSwitch(!forceUpdateSwitch)}
                    showHome={false}

                />
            </Row>
    } catch (e) {
    }
    try {
        segmentationQuestionData = segQuesData ?
            <Select loading={segQuesIsLoading} size={'large'}
                    defaultValue={segQuesData.hasOwnProperty('biweekly_questions') ?
                segQuesData.biweekly_questions[0].id : segQuesData[0].id}
                    placeholder={'Select a segmentation question'}
                    style={{ width: '16.5rem' , marginLeft:'-12px' }}
                    onChange={(questionId) => {
                segmentationQuestionSelected(questionId)
              }}
            >
                {
                    !isCaseStudy &&
                    segQuesData.hasOwnProperty('biweekly_questions') && !isDiary && !biweeklySpecial ?
                        segQuesData.biweekly_questions.map((segmentationQuestion, index) => {
                            return (
                                <Option key={segmentationQuestion.id} value={segmentationQuestion.id}
                                        title={segmentationQuestion.question}>
                                    {segmentationQuestion.short_form}
                                </Option>
                            )

                        }) :
                        segQuesData.map((segmentation) => {
                                return (
                                    <Option key={segmentation.id} value={segmentation.id}
                                            title={segmentation.name}>
                                        {segmentation.name}
                                    </Option>
                                )
                            }
                        )
                }

            </Select>
            :
            segQuesIsLoading ?
                <Spin spinning={true} tip={'loading segmentation questions'} style={{ marginTop: '1em' }}/> : null


    } catch (e) {
    }
    return (

        <Card style={{
      marginLeft: '2em',
      borderRadius: '.625rem',
      boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)'
    }}
        >
            <Row type={'flex'} justify={'space-between'}>
                {
                    visible &&
                    <Col>
                        <Button type={'primary'} onClick={() => handleFilterSegmentations()}> Filter </Button>
                    </Col>
                }
                <Col>
                    <Switch
                        title={'Filter'} defaultChecked={visible} onChange={value => handleSwitchChanged(value)}/>
                </Col>
            </Row>

            {visible && <Divider dashed={true}/>}

            {
                visible && !segQuesIsError && !isCaseStudy && segmentationQuestionData

            }
            {
                visible && segQuesIsError &&
                <Alert message={'Some error occurred loading survey segemntation questions '} type={'error'}/>
            }

            {visible && <Divider dashed={true}/>}


            < Spin
                size={'large'}
                style={
          {
            marginTop: '1em', marginBottom:
              '1em'
          }
        }
                spinning={isLoading}
                indicator={
          <Icon type={'loading'} spin/>
        }>
                <Skeleton
                    loading={isLoading}>
                    {visible &&

                    <Row type={'flex'} justify={'center'} align={'top'} style={{ marginBottom: '1em' }}>

                        {
                            !isError && (isDiary || biweeklySpecial) && segmentationFilters
                        }
                        {isError &&
                        <Button type={'link'} icon={'reload'} onClick={() => setForceUpdateSwitch(!forceUpdateSwitch)}
                                title={'reload segmentation filters'}> Reload Segmentation Filters</Button>}
                    </Row>
                    }
                </Skeleton>
            </Spin>
        </Card>
    )
}

export default SegmentationFilters
