import React, {useEffect, useState} from 'react'
import CustomComponent from '../../CustomComponent'
import {Button, Card, Icon, message, Input, Row, Col, Typography} from 'antd'
import API from '../../effects/API'
import {PASSWORD_RESET_URL} from '../../environment/globals'

const {Title} = Typography;
message.config({
    top: 10,
    duration: 1,
    maxCount: 1
});

class PasswordReset extends CustomComponent {
    constructor(props) {
        super(props);

        if (!props.token)
        {
            window.location.href = "/";
        }

        this.state = {
            token: props.token,
            password: "",
            confirm_password: "",
            isError: false,
            isLoading: false,
            redirecting: false,
            error: "",
        };

        this.passwordReset = this.passwordReset.bind(this);
    }

    passwordReset() {
        const hide = message.loading('Action in progress..', 1);

        const data = {
            password: this.state.password,
            password_confirmation: this.state.confirm_password,
            token: this.state.token
        };

        API.post(PASSWORD_RESET_URL, data).then(res => {
            if (res.data['success'])
            {
                this.updateState({
                    data: res.data,
                    isLoading: false
                }, () => {
                    setTimeout(function () {
                        window.location.href = "/login";
                    }, 2000);
                });
                message.success('Your new password has been set successfully. You can now login to the portal using your email and password.\n Redirecting you to login page...');
            }
            else {
                this.updateState({
                    isError: true,
                    error: res.data['error']
                });
                message.error(res.data['error'], 1)
            }
        }).catch(err => {
            this.updateState({
                isError: true,
                error: "Unable to reset password"
            });
            message.error("Unable to reset password", 1)
        });
    }

    render() {
        return (
            <div>
                <div style={{ margin: '2em' }}>
                    <Card
                        style={{
            padding: 0,
            borderRadius: '.625rem',
            boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)',
            margin: 'auto', marginTop: '.5em', width: '50vw',
            background: '#f5f6f !important'
          }}
                        className={'loginCard'}
                        bodyStyle={{ paddingTop: 0 }}
                        title={
            <Row type={'flex'}
                 style={{
                   flexDirection: 'column',
                   alignItems: 'center',

                   background: '#f5f6f'
                 }}>
              <Title level={2}
                     style={{ textAlign: 'center' }}> PASSWORD RESET </Title>
            </Row>
          }>
                        <Row type={'flex'} style={{ flexDirection: 'column', alignItems: 'center' }}>

                            {/*<Title type={'secondary'} level={4}> Password </Title>*/}
                            <div style={{ width: '30em', marginBottom: '1em' }}>

                                <Input.Password
                                    defaultValue={''}
                                    required={true}
                                    placeholder="New password"
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                                    onChange={(event) => this.updateState({password: event.target.value})}/>

                            </div>
                            {/*<Title type={'secondary'} level={4}> ConfirmPassword </Title>*/}
                            <div style={{ width: '30em', marginBottom: '1em' }}>

                                <Input.Password
                                    defaultValue={''}
                                    required={true}
                                    placeholder="Confirm password"
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                                    onChange={(event) => this.updateState({confirm_password: event.target.value})}/>

                            </div>
                            <Row style={{ width: '30em', marginBottom: '1em'}}>
                                <Col xs={12}>
                                    <a href="/forgot-password">Forgot Password</a>
                                </Col>
                                <Col xs={12} style={{
                                        textAlign: "right"
                                    }}>
                                    <a href="/login">Back to Login</a>
                                </Col>
                            </Row>
                            <div>
                                <Button size={'large'} type={'primary'} onClick={this.passwordReset}> Reset Password </Button>
                            </div>
                        </Row>
                    </Card>
                </div>
            </div>
        )
    }
}

export default PasswordReset