import useDataApi from '../../effects/fetcher'
import {
  SURVEY_TOP_REPORT_URL,
  BASE_URL,
  PERCENTAGE_COLORS,
  SURVEYS_SUMMARY_URL
} from '../../environment/globals'
import { Card, Col, Divider, Empty, Icon, Row, Skeleton, Statistic, Typography } from 'antd'
import RetryHomeRow from '../../ui_pieces/retry_home_row'
import ErrorView from '../../ui_pieces/error_view'
import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'

const { Meta } = Card
const { Title, Text } = Typography

function TopReports() {
  const [{ isLoading, isError, error, data, forceUpdateSwitch }, setForceUpdateSwitch] =
    useDataApi(BASE_URL + SURVEY_TOP_REPORT_URL, [{}, {}, {}, {}, {}])

  let reportsData = null


  const options = {
    title: {
      text: 'My chart'
    },
      colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
          '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a', '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
          '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
          '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
          '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
          '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
          '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
          '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
          '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
          '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
          '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'],
      credits: {
          enabled: false
      },
    series: [{
      data: [1]
    }]
  };

  const HC = () => <div>
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  </div>

  try {
    reportsData = data.length ?
      data.map((reportDatum, index) => {
        return (
          <SurveySummaryCard survey={reportDatum} loading={isLoading} key={index}/>
          // {/*<Col span={4} key={index}>*/}
          //   {/*<Card bordered={true} loading={isLoading}*/}
          //   {/*      bodyStyle={{*/}
          //   {/*        padding: 0*/}
          //   {/*      }}*/}
          //   {/*      style={{*/}
          //   {/*        padding: 0,*/}
          //   {/*        borderRadius: '.625rem',*/}
          //   {/*        boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)'*/}
          //   {/*      }}>*/}
          //
          //   {/*  <Row type={'flex'} justify={'center'} style={{ padding: '1rem' }}>*/}
          //   {/*    <Title style={{ margin: 'auto', fontSize: '.75rem' }} level={2}*/}
          //   {/*           type={'secondary'}> {reportDatum.name ? reportDatum.name.toUpperCase() : null} </Title>*/}
          //   {/*  </Row>*/}
          //
          //   {/*  <Row type={'flex'} justify={'center'}*/}
          //   {/*       style={{ background: 'lightblue', padding: '1rem', color: 'white' }}>*/}
          //   {/*    <Text*/}
          //   {/*      style={{ fontSize: '2.0625rem', margin: 'auto', color: 'white' }}> {reportDatum.description}</Text>*/}
          //   {/*  </Row>*/}
          //
          //   {/*  /!*<Meta title={reportDatum.name} description={reportDatum.description}*!/*/}
          //   {/*  /!*      avatar={<Icon type={'hourglass'}/>}/>*!/*/}
          //   {/*</Card>*/}
          // </Col>
        )
      })
      :
      <Row type={'flex'} justify={'center'}>
        <Empty description={'No report summary could be found for the survey'}/>
        <RetryHomeRow
          retryHandler={() => setForceUpdateSwitch(!forceUpdateSwitch)}
          showHome={false}

        />
      </Row>

  } catch (e) {

  }

  return (

    <Row type={'flex'} justify={'space-around'}>
      {!isError && reportsData}

      {isError && <ErrorView
        error={error}
        retryHandler={() => setForceUpdateSwitch(!forceUpdateSwitch)}
        showHome={false}
      />}

    </Row>


  )
}

function SurveySummaryCard({ loading, survey = {} }) {
  const { name, total_questions, total_answers } = survey

  const percentage_color =
    total_answers >= 80
      ? PERCENTAGE_COLORS.high
      : total_answers >= 50
      ? PERCENTAGE_COLORS.medium
      : PERCENTAGE_COLORS.low;

  return (
    <Col span={4} className={''}>
      <Card hoverable={false} style={{
        borderRadius: '.625rem',
        boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)'
      }}>
        <Skeleton loading={loading} active={true}>

          <Meta
            title={<h1 className="surveys text-center">{name}</h1>}
            style={{ margin: 0, padding: '1.25em .5em' }}
          />

          <Row type="flex" justify="space-around">
            <Col span={10}>
              <Statistic title="Total Questions" value={total_questions}/>
            </Col>
            <Col span={10}>
              <Statistic
                title="Answers"
                value={total_answers}
                precision={2}
                valueStyle={{ color: percentage_color }}
                prefix={<Icon type="bar-chart"/>}
                suffix="%"
              />
            </Col>
          </Row>
        </Skeleton>
      </Card>
    </Col>
  )
}


export default TopReports
