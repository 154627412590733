import {Card, Col, message, Row, Select, Tabs, Typography} from 'antd'
import React, {useState} from 'react'
import CustomComponent from '../../CustomComponent'
import TopReports from './top_reports'
import CategoryQuestions from './category_questions'
import SegmentationFilters from './segmentations'
import SegmentationReport from './segmentation_report'
import NoSegmentationReport from './no_segmentation_report'
import './assets/main.css'
import AggregateQuestion from './aggregate_questions'
import API from '../../effects/API'
import {BASE_URL, GET_BIWEEKLY_SPECIALS_URL} from '../../environment/globals'
import PointData from './point_data'

const {Title, Text} = Typography;

const {TabPane} = Tabs;

const {Option} = Select;

class Specials extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            filterVisible: true,
            selectedQuestion: {},
            segmentationQuestion: null,
            segmentationFilters: [],
            biweeklyFilters: [],
            aggregateData: {questions: [], computation: 1},
            isLoading: false,
            isError: false,
            biweeklyId: sessionStorage.getItem('surveyId'),
            isDiary: false,
            biweeklySpecial: true,
            error: '',
            data: null,
        };

        this.switchFilter = this.switchFilter.bind(this);
        this.questionSelected = this.questionSelected.bind(this);
        this.segmentationQuestionSelected = this.segmentationQuestionSelected.bind(this);
        this.filterSegmentations = this.filterSegmentations.bind(this);
        this.calculateAggregate = this.calculateAggregate.bind(this);
    }

    componentDidMount()
    {
        super.componentDidMount();
        this.getBiweeklySpecials();
    }

    getBiweeklySpecials()
    {
        API.get(GET_BIWEEKLY_SPECIALS_URL).then(res => {
            this.updateState({
                data: res.data,
                isLoading: false
            })
        }).catch(err => {
            this.updateState({
                isError: true,
                error: err
            });
        });
    }

    switchFilter = (value) => {
        this.updateState({
            filterVisible: value
        })
    };
    questionSelected = (question) => {
        this.updateState({
            selectedQuestion:question
        })
    };

    segmentationQuestionSelected = (question) => {
        this.updateState({
            segmentationQuestion: question
        })
    };

    filterSegmentations(filters, biweeklyFilters) {
        if (filters.length !== this.state.segmentationFilters.length) {
            this.updateState({
                segmentationFilters:filters
            });
        } else {
            filters.forEach((item, index) => {
                if (item !== this.state.segmentationFilters[index]) {

                    this.updateState({
                        segmentationFilters:filters
                    });
                }
            });
        }
        this.updateState({
            biweeklyFilters: biweeklyFilters
        });
    };

    calculateAggregate(questions, computation) {
        if (questions.length >= 2) {

            let firstType = questions[0].type;
            let collision = false;
            questions.forEach(question => {
                if (question.type !== firstType) {
                    collision = true
                }
            });
            
            if (collision) {
                message.error('Make sure the selected questions have the same type!')
            } else {
                this.questionSelected({
                    name: `${questions.length} questions selected`,
                    short_form: `${questions.length} questions selected`,
                    id: -1,
                    parent: 0
                });
                this.updateState({
                    aggregateData: {
                        questions: questions.map(question => question.id),
                        computation: computation
                    }
                })
            }

        } else {
            message.warning('Make sure 2 more or numeric questions are selected!')
        }
    };

    render() {
        return (
            <div>
                <Row type={'flex'} justify={'space-between'} style={{ paddingTop: '2em', marginBottom: '2em' }}>
                    <Col span={12} style={{ paddingLeft: '2em', color: 'white' }}>

                        <Text level={4} secondary="true" style={{ color: '#e9e9e9' }}> Dashboard </Text>
                        <br/>
                        <Title level={2}
                               style={{
                   marginTop: 0,
                   color: '#e9e9e9'
                 }}> {sessionStorage.getItem('surveyName', '')} Overview </Title>

                    </Col>
                    {/*<Col span={24}>*/}
                    {/*<TopReports/>*/}
                    {/*</Col>*/}
                </Row>
                <Row type={'flex'} justify={'space-between'}>
                    <Col span={6}>
                        <Tabs defaultActiveKey={"1"}>
                            { this.state.biweeklyId && this.state.biweeklyId > 0 &&
                                <TabPane tab={<span>Direct Questions</span>} key={1}>
                                    <CategoryQuestions
                                        questionSelected={this.questionSelected}
                                        isDiary={false}
                                    />
                                </TabPane>
                            }

                        </Tabs>
                    </Col>

                    <Col span={18}>
                        <Row type={'flex'} justify={'space-between'} style={{ margin: '3em' }}>
                            <Col span={this.state.filterVisible ? 16 : 22}>

                                <Tabs defaultActiveKey={"1"} type={'card'} animated={true}>
                                    <TabPane
                                        tab={<span style={{ fontSize: '1.1em', padding: '3em 4em', fontWeight: 'bold' }}>Segmentation Report</span>}
                                        key={1}>
                                        {<SegmentationReport selectedQuestion={this.state.selectedQuestion}
                                                             segmentationQuestion={this.state.segmentationQuestion}
                                                             segmentationFilters={this.state.segmentationFilters}
                                                             biweeklyFilters={this.state.biweeklyFilters}
                                                             aggregateData={this.state.aggregateData}
                                                             questionSelected={this.questionSelected}
                                                             isDiary={false}
                                                             biweeklySpecial={true}

                                        />}
                                    </TabPane>
                                    {this.state.selectedQuestion && this.state.selectedQuestion.type !== "Multiple" &&
                                    <TabPane
                                        tab={<span
                        style={{
                          fontSize: '1.1em',
                          padding: '2em 4em',
                          fontWeight: 'bold'
                        }}>No Segmentation Report</span>}
                                        key={2}>
                                        {
                                            <NoSegmentationReport
                                                selectedQuestion={this.state.selectedQuestion}
                                                segmentationFilters={this.state.segmentationFilters}
                                                biweeklyFilters={this.state.biweeklyFilters}
                                                isDiary={false}
                                                biweeklySpecial={true}
                                            />
                                        }
                                    </TabPane>
                                    }
                                </Tabs>
                                {
                                    this.state.selectedQuestion && (this.state.selectedQuestion.type === "Numeric" || this.state.selectedQuestion.type === "Money") &&
                                <PointData selectedQuestion={this.state.selectedQuestion}
                                           segmentationQuestion={this.state.segmentationQuestion}
                                           isDiary={false}
                                           biweeklySpecial={true}/>
                                }


                            </Col>
                            <Col span={this.state.filterVisible ? 8 : 2}>

                                {<SegmentationFilters switchFilter={this.switchFilter}
                                                      segmentationQuestionSelected={this.segmentationQuestionSelected}
                                                      filterSegmentations={this.filterSegmentations}
                                                      isDiary={false}
                                                      biweeklySpecial={true}

                                />}
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </div>
        )
    }
}
export default Specials;