import React from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import {Row, Col} from 'antd';
import {getChildrenToRender} from './utils';
import {isImg} from './utils';

class Footer extends React.Component {
    static defaultProps = {
        className: 'footer1',
    };

    getLiChildren = (data) =>
        data.map((item, i) => {
            const {title, childWrapper, ...itemProps} = item;
            return (
                <Col key={i.toString()} {...itemProps} title={null} content={null}>
                    <h2 {...title}>
                        {typeof title.children === 'string' &&
                        title.children.match(isImg) ? (
                                <img src={title.children} width="100%" alt="img"/>
                            ) : (
                                title.children
                            )}
                    </h2>
                    <div {...childWrapper}>
                        {childWrapper.children.map(getChildrenToRender)}
                    </div>
                </Col>
            );
        });

    render() {
        const {...props} = this.props;
        const {dataSource} = props;
        delete props.dataSource;
        delete props.isMobile;
        const childrenToRender = this.getLiChildren(dataSource.block.children);
        return (
            <div {...props} {...dataSource.wrapper} style={{backgroundColor:"white"}}>
                <Row type={'flex'} justify={'space-between'} style={{ margin: '3em'}}>

                    <Col span={4}>
                        <img src={'/da.png'}
                             style={{
               display: 'block',
               margin: 'auto',
               width: '200px',
               height: '150px',
               borderRadius: '4%',
               // boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)',
               objectFit: 'contain'

             }}/>
                    </Col>
                    <Col span={4}>
                        <img src={'/phb.png'}
                             style={{
               display: 'block',
               margin: 'auto',
               width: '300px',
               height: '150px',
               borderRadius: '4%',
               // boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)',
               objectFit: 'contain'

             }}/>
                    </Col>
                    <Col span={4}>
                        <img src={'/logo_without_text.jpg'}
                             style={{
               display: 'block',
               margin: 'auto',
               width: '100px',
               height: '150px',
               borderRadius: '14%',
               // boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)',
               objectFit: 'contain'

             }}/>
                    </Col>
                    <Col span={4}>
                        <img src={'/fin.jpg'}
                             style={{
               display: 'block',
               margin: 'auto',
               width: '250px',
               height: '150px',
               borderRadius: '4%',
               // boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)',
               objectFit: 'contain'

             }}/>
                    </Col>
                    <Col span={4}>
                        <img src={'/awe.jpg'}
                             style={{
               display: 'block',
               margin: 'auto',
               width: '150px',
               height: '150px',
               borderRadius: '4%',
               // boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)',
               objectFit: 'contain'

             }}/>
                    </Col>
                </Row>


            </div>
        );
    }
}

export default Footer;
