import React from 'react'
import { Result } from 'antd'
import RetryHomeRow from './retry_home_row'

function ErrorView({ error, retryHandler }) {
  const errorHasCode = error.hasOwnProperty('code')
  return (
    <Result
      status={errorHasCode ? error.code + '' : 'error'}
      title={
        errorHasCode ? +error.code + ' - ' + error.message : 'Network Error'
      }
      subTitle={
        errorHasCode
          ? 'please try again later'
          : 'check your internet connection'
      }
      extra={<RetryHomeRow retryHandler={retryHandler} />}
    />
  )
}

export default ErrorView
