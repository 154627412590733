/*
 system wide available constants
 */
const PERCENTAGE_COLORS = {
    low: '#ec6f66',
    medium: '#348AC7',
    high: '#85D8CE'
}

// const COLORS = ["#7474bf", "#348ac7", "#ec6f66", "#f3a183", "#959398", "#283048",
//   "#757f9a", "#d7dde8", "#4389a2", "#5c25ad", "#71b280", "#134e5e",
//   "#85d8ce", "#085078", "#516395", "#614385", "#928dab", "#1f1c2c", "#3a6073", "#16222a"]

const BASE_URL = "https://riseapi.l-ift.com";
// const BASE_URL = "http://localhost:4000";

const SURVEYS_SUMMARY_URL = '/api/surveys-summary';

const SURVEY_TOP_REPORT_URL = '/api/top-reports';

const REQUEST_ACCESS_URL = '/api/user/request';

const RESPONDENTS_URL = '/api/respondents';

const RESET_PASSWORD_URL = '/api/user/reset_password';

const PASSWORD_RESET_URL = '/api/user/password_reset';

// const SURVEY_QUESTIONS_URL = '/api/biweeklies/' + sessionStorage.getItem('sessionId') + '/questions'

// const SURVEY_SEGMENTATION_QUESTIONS_URL = '/api/biweeklies/1/segmentation-questions'

const SURVEY_SEGMENTATIONS_URL = '/api/segmentations-filters';

const SURVEY_SEGMENTATION_REPORT_URL = '/api/survey_line_responses';

const SURVEY_SEGMENTATION_FILTER_REPORT_URL = '/api/filter-segmentations-data';

const SURVEY_NO_SEGMENTATION_REPORT_URL = '/api/no-segmentation-data';

// const SURVEY_GET_AGGREGATE_QUESTIONS_URL = '/api/biweeklies/100/get-aggregate-questions'

const LOGIN_URL = '/api/oauth/authorize';


const GET_BIWEEKLY_SPECIALS_URL = '/api/biweeklies/get-biweekly-specials';

const GET_DIARIES_CATEGORIES = '/api/diaries/get-categories';

const GET_QUICK_INFOS = '/api/diaries/get-quick-info';

const AGGREGATE_COMPUTATION = {
    Summation: 1,
    Subtraction: 2
};

const DELETE_COOKIES = () => {
    document.cookie = 'email=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'access_token=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
};
const GET_COOKIE = function(cname) {
    try {
        let name = cname + '=';
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    } catch (e) {
        return '';
    }
};

// credentials
const CLIENT_ID = '$2y$10$jvw/V6Fo9mvp4JXDCYYI..123uYpTEl27';
const CLIENT_SECRET = '$2y$10$9OqJjxC9qZKC92L.123nO7hVOPY0436eU';

export {
    PERCENTAGE_COLORS,
    BASE_URL,
    SURVEYS_SUMMARY_URL,
    REQUEST_ACCESS_URL,
    RESET_PASSWORD_URL,
    PASSWORD_RESET_URL,
    SURVEY_TOP_REPORT_URL,
    // SURVEY_QUESTIONS_URL,
    SURVEY_SEGMENTATIONS_URL,
    // SURVEY_SEGMENTATION_QUESTIONS_URL,
    SURVEY_SEGMENTATION_REPORT_URL,
    SURVEY_NO_SEGMENTATION_REPORT_URL,
    SURVEY_SEGMENTATION_FILTER_REPORT_URL,
    // SURVEY_GET_AGGREGATE_QUESTIONS_URL,
    LOGIN_URL,
    RESPONDENTS_URL,
    GET_BIWEEKLY_SPECIALS_URL,
    GET_DIARIES_CATEGORIES,
    GET_QUICK_INFOS,
    CLIENT_ID,
    CLIENT_SECRET,
    GET_COOKIE,
    DELETE_COOKIES,
    AGGREGATE_COMPUTATION
}
