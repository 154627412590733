import React from 'react'
import CustomComponent from '../CustomComponent'
import {Card, Col, Divider, Empty, Row, Skeleton, Statistic, Typography} from 'antd'
import {BASE_URL, GET_BIWEEKLY_SPECIALS_URL} from '../environment/globals'

import API from '../effects/API'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faWallet} from '@fortawesome/free-solid-svg-icons'
import {faHandsHelping} from '@fortawesome/free-solid-svg-icons'
import {faShareSquare} from '@fortawesome/free-solid-svg-icons'
import './assets/main.less'

import RetryHomeRow from '../ui_pieces/retry_home_row'
import ErrorView from '../ui_pieces/error_view'


const {Meta} = Card;
const {Title} = Typography;
const {Text} = Typography;
const categoryIcons = [
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
];

const categoryClicked = (biweeklyId, biweeklyName, isBiweeklySpecialsSelected = false, defaultBiweeklySpecialName = '') => {
    sessionStorage.setItem('surveyId', biweeklyId);
    sessionStorage.setItem('biweekly_specials', true);
    sessionStorage.setItem('selectedBiweeklySpecial', defaultBiweeklySpecialName);
    sessionStorage.setItem('isDiary', false);
    sessionStorage.setItem('surveyName', 'Specials / ' + biweeklyName);
    window.location.href = '/specials';
};

class SpecialCategory extends CustomComponent {
    constructor(props) {
        super(props);
        const CATEGORY_COLORS = [
            '#F95700FF',
            '#00539CFF',
            '#2C5F2DFF',
            '#D85A7FFF',
            '#603F83FF',
            '#aa2761FF',
            '#2BAE66FF',
            '#9d9100FF',
            '#F2AA4CFF',
            '#2C5F2DFF',
            '#41a1f2FF',
            '#D85A7FFF',
            '#00539CFF',
            '#603F83FF',
            '#F2AA4CFF',
            '#2098a2FF',
            '#603F83FF',
            '#7b31cdFF'];

        this.state = {
            loading: props.loading,
            biweekly: props.biweekly,
            color: CATEGORY_COLORS[props.biweekly.index % CATEGORY_COLORS.length]
        }
    }

    render() {
        return (
            <Col xs={24} md={12} lg={8} className={'surveys mid-padded'}>
                <Card style={{
                borderWidth: 0
            }} hoverable={!this.state.loading}
                      onClick={() => categoryClicked(this.state.biweekly.id, this.state.biweekly.description)}
                      bodyStyle={{ border: "solid", borderColor: this.state.color, borderWidth: 2, borderRadius:100 }}>

                    <Skeleton loading={this.state.loading} active={true}>
                        <Divider>
                            <Meta
                                title={<h1 className="surveys text-center" style={{ backgroundColor: this.state.color, color: "white", padding:"3px", paddingLeft: "20px", paddingRight:"20px", borderRadius: 25 }}>{this.state.biweekly.description}</h1>}
                                style={{ margin: 0, color: this.state.color }}
                                description={<span style={{ color: this.state.color }}>{categoryIcons[this.state.biweekly.index % categoryIcons.size]}</span>}

                            />
                        </Divider>
                        <Row type="flex" justify="space-around">

                            <Statistic valueStyle={{ textAlign: 'center', color: this.state.color }}
                                       title={<span style={{ textAlign: 'center', color: this.state.color }}> Total Questions </span>}
                                       value={this.state.biweekly.total_questions}/>

                        </Row>
                    </Skeleton>
                </Card>

            </Col>
        )
    }
}

class SpecialCategories extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: true,
            isError: false,
            error: ""
        };

        this.reloadBiweeklySpecials = this.reloadBiweeklySpecials.bind(this);
    }

    componentDidMount()
    {
        super.componentDidMount();
        this.reloadBiweeklySpecials();
    }

    reloadBiweeklySpecials() {
        this.updateState({
            isError: false
        });
        API.get(GET_BIWEEKLY_SPECIALS_URL).then(res => {
            this.updateState({
                data: res.data,
                isLoading: false
            })
        }).catch(err => {
            this.updateState({
                isError: true,
                error: err
            });
        });
    }

    render() {
        let surveyReviews = null;
        // try to parse data and prepare a view consisting
        // of survey review cards
        try {
            if (this.state.data.length) {
                surveyReviews = this.state.data.map((biweekly, index) => {
                    biweekly.index = index;
                        return (
                            <SpecialCategory
                                key={index}
                                loading={this.state.isLoading}
                                biweekly={biweekly}
                            />
                        )
                    }
                );
            }
            else {
                surveyReviews = (
                    <div>
                        <Empty description={'No data could be found'}/>
                        <RetryHomeRow
                            retryHandler={() => this.reloadBiweeklySpecials()}
                        />
                    </div>
                );
            }
        } catch (e) {
        }
        return (
            <div className="surveys high-margin">
                <Row type={'flex'} justify={'center'} className={'surveys no-margin'}>
                    <Title className={''}>Special Categories </Title>
                </Row>
                <Row type={'flex'} justify={'center'} className={'surveys no-margin'}>
                    <Title level={2} className={''}>
                        Select a Special Category
                    </Title>
                </Row>

                <Row type={'flex'} justify={'center'} style={{ marginBottom: '2em' }}>
                    <Text strong>
                        There are {this.state.data.length} special categories in the system
                    </Text>
                </Row>

                {!this.state.isError &&
                <Row type={'flex'} justify={'center'}>
                    {surveyReviews}
                </Row>
                }


                {this.state.isError && (
                    <Row type={'flex'} justify={'center'}>
                        <ErrorView
                            error={this.state.error}
                            retryHandler={() => this.reloadBiweeklySpecials()}
                        />
                    </Row>
                )}
            </div>
        )
    }
}

export default SpecialCategories;
