import React, {useEffect, useState} from 'react'
import CustomComponent from '../../CustomComponent'
import {Button, Card, Icon, message, Input, Row, Col, Typography} from 'antd'
import API from '../../effects/API'
import {REQUEST_ACCESS_URL} from '../../environment/globals'

const {Title} = Typography;
message.config({
    top: 10,
    duration: 1,
    maxCount: 1
});

class RequestAccess extends CustomComponent {
    constructor(props) {
        super(props);

        this.state = {
            first_name: "",
            last_name: "",
            email: "",
            organization: "",
            reason_for_access: "",
            isError: false,
            isLoading: false,
            redirecting: false,
            error: "",
        };

        this.sendRequest = this.sendRequest.bind(this);
    }

    sendRequest() {
        const hide = message.loading('Action in progress..', 1);

        const data = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            organization: this.state.organization,
            reason_for_access: this.state.reason_for_access
        };

        API.post(REQUEST_ACCESS_URL, data).then(res => {
            if (res.data['success'])
            {
                this.updateState({
                    data: res.data,
                    isLoading: false
                });
                message.success('Your request has been sent. We will verify your request and give you access.');
            }
            else {
                this.updateState({
                    isError: true,
                    error: res.data['error']
                });
                message.error(res.data['error'], 1)
            }
        }).catch(err => {
            this.updateState({
                isError: true,
                error: "Your request was not submitted correctly. Please try again"
            });
            message.error("Your request was not submitted correctly. Please try again", 1)
        });
    }

    render() {
        return (
            <div>
                <div style={{ margin: '2em' }}>
                    <Card
                        style={{
            padding: 0,
            borderRadius: '.625rem',
            boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)',
            margin: 'auto', marginTop: '.5em', width: '50vw',
            background: '#f5f6f !important'
          }}
                        className={'loginCard'}
                        bodyStyle={{ paddingTop: 0 }}
                        title={
            <Row type={'flex'}
                 style={{
                   flexDirection: 'column',
                   alignItems: 'center',

                   background: '#f5f6f'
                 }}>
              <Title level={2}
                     style={{ textAlign: 'center' }}> REQUEST ACCESS </Title>
            </Row>
          }>
                        <Row type={'flex'} style={{ flexDirection: 'column', alignItems: 'center' }}>

                            {/*<Title type={'secondary'} level={4}> First Name </Title>*/}
                            <div style={{ width: '30em', marginBottom: '1em', marginTop: '1em' }}>
                                <Input
                                    required={true}
                                    value={this.state.first_name}
                                    placeholder="First Name"
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                                    onChange={(event) => this.updateState({first_name: event.target.value})}
                                />

                            </div>
                            {/*<Title type={'secondary'} level={4}> Last Name </Title>*/}
                            <div style={{ width: '30em', marginBottom: '1em' }}>
                                <Input
                                    required={true}
                                    value={this.state.last_name}
                                    placeholder="Last Name"
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                                    onChange={(event) => this.updateState({last_name: event.target.value})}
                                />

                            </div>
                            {/*<Title type={'secondary'} level={4}> Email </Title>*/}
                            <div style={{ width: '30em', marginBottom: '1em' }}>
                                <Input
                                    required={true}
                                    type="email"
                                    value={this.state.email}
                                    placeholder="Email Address"
                                    prefix={<Icon type="compose" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                                    onChange={(event) => this.updateState({email: event.target.value})}
                                />

                            </div>
                            {/*<Title type={'secondary'} level={4}> Organization </Title>*/}
                            <div style={{ width: '30em', marginBottom: '1em' }}>
                                <Input
                                    required={true}
                                    value={this.state.organization}
                                    placeholder="Organization"
                                    prefix={<Icon type="briefcase" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                                    onChange={(event) => this.updateState({organization: event.target.value})}
                                />

                            </div>

                            {/*<Title type={'secondary'} level={4}> Reason for access </Title>*/}
                            <div style={{ width: '30em', marginBottom: '1em' }}>
                                <Input
                                    required={true}
                                    type="email"
                                    value={this.state.reason_for_access}
                                    placeholder="Reason for access"
                                    prefix={<Icon type="question" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                                    onChange={(event) => this.updateState({reason_for_access: event.target.value})}
                                />

                            </div>
                            <Row style={{ width: '30em', marginBottom: '1em'}}>
                                <Col xs={12}>
                                    <a href="/reset-password">Forgot Password</a>
                                </Col>
                                <Col xs={12} style={{
                                        textAlign: "right"
                                    }}>
                                    <a href="/login">Back to Login</a>
                                </Col>
                            </Row>
                            <div>
                                <Button size={'large'} type={'primary'} onClick={this.sendRequest}> Send Request </Button>
                            </div>


                        </Row>
                    </Card>
                </div>
            </div>
        )
    }
}

export default RequestAccess