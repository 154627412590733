import {Alert, Card, Select, Spin, Typography, Button} from 'antd'
import React, {useEffect, useState} from 'react'
import useDataApi from '../../effects/fetcher'
import PieChart from 'highcharts-react-official'
import HighchartsReact from 'highcharts-react-official'

import {
    BASE_URL,
    SURVEY_SEGMENTATION_FILTER_REPORT_URL,
    SURVEY_SEGMENTATION_REPORT_URL
} from '../../environment/globals'
import Highcharts from 'highcharts'

require('highcharts/modules/exporting.js')(Highcharts);
require('highcharts/modules/export-data.js')(Highcharts);


const {Meta} = Card;
const {Title, Text} = Typography;
const {Option} = Select;

function SegmentationReport({
    selectedQuestion, segmentationQuestion, segmentationFilters, biweeklyFilters,
    aggregateData, questionSelected, isDiary = false, biweeklySpecial = false
}) {

    const [{isLoading, isError, error, data, forceUpdateSwitch}, setForceUpdateSwitch, setURL] =
        useDataApi(BASE_URL + SURVEY_SEGMENTATION_REPORT_URL, [{}, {}, {}, {}, {}], {}, 'POST');

    const questionType = isDiary ? 'repeat_question' : 'biweekly_question';

    const detailURL = isDiary ? '/api/repeat_questions' : '/api/biweekly_questions';

    const [{
        isLoading: subQuestionsLoading, isError: subQuestionsIsError,
        error: subQError, data: questionDetail, forceUpdateSwitch: subQforceUpdateSwitch
    }, subQsetForceUpdateSwitch, setsubQURL] =
        useDataApi(BASE_URL + detailURL + '/' + selectedQuestion.id + '/detail', [{}, {}, {}, {}, {}]);


    const [subQuestions, setSubQuestions] = useState([]);
    const [parentQuestion, setParentQuestion] = useState([]);


    useEffect(() => {
        setsubQURL(BASE_URL + detailURL + '/' + selectedQuestion.id + '/detail');
        if (selectedQuestion.type === 'Multiple') {
            if (questionDetail.hasOwnProperty(questionType)) {
                setParentQuestion(selectedQuestion)
                setSubQuestions(questionDetail[questionType].children)
            }
        } else if (questionDetail.hasOwnProperty(questionType) &&
            questionDetail[questionType].id === selectedQuestion.id && questionDetail[questionType].parent === 0) {
            setSubQuestions([])
        }

        // append parameter for diary questions
        const segmentationReportURL = isDiary ?
            '/api/repeat_response' :
            biweeklySpecial ?
                '/api/biweekly_response' :
                '/api/survey_line_responses';

        const segmentationFilterURL = isDiary || biweeklySpecial ?
            '/api/diaries/filter-segmentations-data' :
            SURVEY_SEGMENTATION_FILTER_REPORT_URL;

        // const biweeklySpecialParameter = biweeklySpecial ? 'is_biweekly_special_question=true' : ''
        const segmentationParameter = isDiary || biweeklySpecial ? '&segmentation_id=' : '&filter_question_id=';
        const overviewQuestionParameter = isDiary || biweeklySpecial ? '&overview_question=overview' : '';

        const aggregateComputationParameter = aggregateData.computation === 1 ? 'sum=true' : '';


        if (selectedQuestion && selectedQuestion.id > 0 && segmentationQuestion && (segmentationQuestion === "biweekly" || segmentationQuestion > 0)) {

            if (segmentationFilters != null && segmentationFilters.length) {
                let url_encoded_filters = '';
                segmentationFilters.forEach((item, index) => {
                    url_encoded_filters += '&filters[]=' + item
                });
                if (isDiary)
                {
                    if (biweeklyFilters != null && biweeklyFilters.length) {
                        biweeklyFilters.forEach((item, index) => {
                            url_encoded_filters += '&biweekly_filter[]=' + item
                        });
                    }
                    else {
                        url_encoded_filters += '&biweekly_filter[]='
                    }
                }
                if (isDiary) {
                    setURL(BASE_URL + segmentationReportURL + '?question_id=' + selectedQuestion.id + ''
                        + segmentationParameter + segmentationQuestion + overviewQuestionParameter + url_encoded_filters)
                } else if (biweeklySpecial) {
                    setURL(BASE_URL + segmentationReportURL + '?question_id=' + selectedQuestion.id + '' +
                        segmentationParameter + segmentationQuestion + overviewQuestionParameter + url_encoded_filters
                    )
                } else {
                    setURL(BASE_URL + '/api/filter-segmentations-data/' + selectedQuestion.id + '/' + segmentationQuestion + '?' + url_encoded_filters)
                }

            } else {
                let url_encoded_filters = '';
                if (isDiary)
                {
                    if (biweeklyFilters != null && biweeklyFilters.length) {
                        biweeklyFilters.forEach((item, index) => {
                            url_encoded_filters += '&biweekly_filter[]=' + item
                        });
                    }
                    else {
                        url_encoded_filters += '&biweekly_filter[]='
                    }
                }
                setURL(BASE_URL + segmentationReportURL + '?question_id=' + selectedQuestion.id + '' +
                    segmentationParameter + segmentationQuestion + overviewQuestionParameter + url_encoded_filters
                )
            }

        } else if (aggregateData.questions.length) {
            let url_encoded_aggregated_questions = '';
            aggregateData.questions.forEach((item, index) => {
                url_encoded_aggregated_questions += '&aggregated_questions[]=' + item
            });

            const aggregateReportURL = isDiary ? '/api/diaries/get-aggregated-report' :
                '/api/biweeklies/get-aggregated-report';

            setURL(BASE_URL + aggregateReportURL + '?' + aggregateComputationParameter + url_encoded_aggregated_questions)
        }


    }, [selectedQuestion, segmentationQuestion, setURL, segmentationFilters, biweeklyFilters, aggregateData, data, isDiary, biweeklySpecial, setsubQURL, detailURL, questionDetail]);

    console.log(selectedQuestion);

    const subQuestionsData = subQuestions.length && (selectedQuestion.type == 'Multiple' || selectedQuestion.parent > 0) ?

        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Button onClick={() => {
                    questionSelected({
                  id: parentQuestion.id,
                  short_form: parentQuestion.short_form,
                  name: parentQuestion.question,
                  type: parentQuestion.type,
                  parent: parentQuestion.parent
                })
                }
            }>Overview</Button><br/><br/>
            <Select loading={subQuestionsLoading} size={'large'}
                    defaultValue={'No question selected'}
                    placeholder={'Select a sub question'}
                    style={{ width: '20rem' }}
                    value={selectedQuestion.short_form}

                    onChange={(question) => {
                        question = JSON.parse(question);
                questionSelected({
                  id: question.id,
                  short_form: question.short_form,
                  name: question.question,
                  type: question.type,
                  parent: question.parent
                })
              }}

            >
                {

                    subQuestions.map((sub_question, index) => {
                        return (
                            <Option key={sub_question.id} value={JSON.stringify(sub_question)}
                                    title={sub_question.question}>
                                {sub_question.short_form}
                            </Option>
                        )
                    })
                }
            </Select>
        </div>
        : null;


    const pieChartOptions = {
        chart: {
            type: 'pie'
        },
        colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
            '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a', '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
            '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
            '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
            '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
            '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
            '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
            '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
            '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
            '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
            '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'],
        title: {
            text: 'Aggregate Questions Result'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        menuItems: [
            {
                textKey: 'print chart',
                onclick: () => this.print()
            },
            {
                separator: true
            }
        ],
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                },
                showInLegend: true
            }
        },
        series: [{
            name: 'Aggregate Questions Result',
            colorByPoint: true,
            data: data.hasOwnProperty('aggregate-report') ? data['aggregate-report'] : []
        }],
        credits: {
            enabled: false
        }
    };

    const barChartOptions = {
        chart: {
            type: 'column',
            height: 750,
            marginBottom: 350
        },
        title: {
            text: selectedQuestion.short_form
        },
        xAxis: {
            categories: data.hasOwnProperty('categories') ? data['categories'] : []
        },
        colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
            '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a', '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
            '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
            '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
            '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
            '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
            '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
            '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
            '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
            '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
            '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'],
        yAxis: {
            min: 0,
            title: {
                text: selectedQuestion.short_form || 'No question selected'
            }

        },
        legend: {
            layout: 'horizontal',
            align: 'left',
            verticalAlign: 'top',
            x: 10,
            y: 580,
            width: "100%",
            floating: true,
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false
        },
        tooltip: {
            shared: true,
            formatter: function () {
                let mytotal = 0;

                for (let i = 0; i < this.points.length; i++) {
                    mytotal += parseInt(this.points[i].y);
                }

                let string = "";

                for (let i = 0; i < this.points.length; i++) {
                    const pcnt = mytotal > 0 ? (this.points[i].y / mytotal) * 100 : 0;
                    const name = this.points[i].series.name;
                    const color = this.points[i].color;
                    string += `<span style="font-weight: bold;color:${color}">${name}</span>: <b>${this.points[i].y}</b> (${Highcharts.numberFormat(pcnt)}%)<br/>`
                }
                return string;
            }
        },
        series: data.hasOwnProperty('series') ? data.series : data.hasOwnProperty('report') ? data.report : [],
        credits: {
            enabled: false
        },
        exporting: {
            showTable: true
        }
    };

    const HC = () => <div>
        <HighchartsReact
            style={{
            height: 500}
            }
            immutable={false}
            highcharts={Highcharts}
            options={barChartOptions}

        />
    </div>;


    return (


        <Card bordered={true} color={'blue'} bodyStyle={{ background: 'white' }}

              style={{
            padding: 0,
            borderRadius: '.625rem',
            boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)'
          }}
              title={<Meta

            title={<Title type={'secondary'} level={4} style={{ marginBottom: 0 }}> With Segmentation </Title>}
            description={'Visualize segmented data'}/>
          }>

            <Spin active="active" size={'large'} spinning={isLoading}>

                {subQuestionsData !== null ? subQuestionsData : <div/>}

                <Alert
                    message={selectedQuestion.id ? 'Question selected' : 'Select a question'}
                    type={selectedQuestion.id ? 'info' : 'warning'}
                    description={selectedQuestion.name || 'No statistical information to display'}
                    showIcon
                />
                {
                    data.hasOwnProperty('aggregate-report') ?
                        <PieChart options={pieChartOptions} highcharts={Highcharts}/> :
                        selectedQuestion.id && <HC/>
                }
            </Spin>


        </Card>
    )
}

export default SegmentationReport