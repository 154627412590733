import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {getChildrenToRender} from './utils';

class Banner5 extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {...tagProps} = this.props;
        const {dataSource} = tagProps;
        delete tagProps.dataSource;
        delete tagProps.isMobile;
        const animType = {
            queue: 'bottom',
            one: {
                y: '+=30',
                opacity: 0,
                type: 'from',
                ease: 'easeOutQuad',
            },
        };
        return (
            <div {...tagProps} {...dataSource.wrapper}>
                <div {...dataSource.page}>
                    <QueueAnim
                        key="text"
                        type={animType.queue}
                        leaveReverse
                        ease={['easeOutQuad', 'easeInQuad']}
                        {...dataSource.childWrapper}
                        componentProps={{
              md: dataSource.childWrapper.md,
              xs: dataSource.childWrapper.xs,
            }}
                    >
                        {dataSource.childWrapper.children.map(getChildrenToRender)}
                    </QueueAnim>
                    <TweenOne animation={animType.one} key="title" {...dataSource.image}>
                        <Carousel style={{width:"1000px"}}>
                            <div>
                                <img alt="1" src="assets/2.jpg" />
                                {/*<p className="legend">Legend 1</p>*/}
                            </div>
                            <div>
                                <img alt="2" src="assets/3.jpg" />
                                {/*<p className="legend">Legend 2</p>*/}
                            </div>
                            <div>
                                <img alt="3" src="assets/5.jpg" />
                                {/*<p className="legend">Legend 3</p>*/}
                            </div>
                            <div>
                                <img alt="4" src="assets/7.jpg" />
                                {/*<p className="legend">Legend 3</p>*/}
                            </div>
                            <div>
                                <img alt="5" src="assets/8.jpg" />
                                {/*<p className="legend">Legend 3</p>*/}
                            </div>
                        </Carousel>
                        {/*<img src={dataSource.image.children} width="100%" alt="img"/>*/}
                    </TweenOne>
                </div>
            </div>
        );
    }
}
export default Banner5;
