import React, {useEffect, useState} from 'react'
import CustomComponent from '../../CustomComponent'
import {Button, Card, Icon, message, Input, Row, Col, Typography} from 'antd'
import API from '../../effects/API'
import {RESET_PASSWORD_URL} from '../../environment/globals'

const {Title} = Typography;
message.config({
    top: 10,
    duration: 1,
    maxCount: 1
});

class ResetPassword extends CustomComponent {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            isError: false,
            isLoading: false,
            redirecting: false,
            error: "",
        };

        this.resetPassword = this.resetPassword.bind(this);
    }

    resetPassword() {
        const hide = message.loading('Action in progress..', 1);

        const data = {
            email: this.state.email,
        };

        API.post(RESET_PASSWORD_URL, data).then(res => {
            if (res.data['success'])
            {
                this.updateState({
                    data: res.data,
                    isLoading: false
                });
                message.success('Your password reset request has been submitted. We will email you instructions to reset your password.');
            }
            else {
                this.updateState({
                    isError: true,
                    error: res.data['error']
                });
                message.error(res.data['error'], 1)
            }
        }).catch(err => {
            this.updateState({
                isError: true,
                error: "Unable to complete request"
            });
            message.error("Unable to complete request", 1)
        });
    }

    render() {
        return (
            <div>
                <div style={{ margin: '2em' }}>
                    <Card
                        style={{
            padding: 0,
            borderRadius: '.625rem',
            boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)',
            margin: 'auto', marginTop: '.5em', width: '50vw',
            background: '#f5f6f !important'
          }}
                        className={'loginCard'}
                        bodyStyle={{ paddingTop: 0 }}
                        title={
            <Row type={'flex'}
                 style={{
                   flexDirection: 'column',
                   alignItems: 'center',

                   background: '#f5f6f'
                 }}>
              <Title level={2}
                     style={{ textAlign: 'center' }}> RESET PASSWORD </Title>
            </Row>
          }>
                        <Row type={'flex'} style={{ flexDirection: 'column', alignItems: 'center' }}>
                            {/*<Title type={'secondary'} level={4}> Email </Title>*/}
                            <div style={{ width: '30em', marginBottom: '1em', marginTop: '1em' }}>
                                <Input
                                    required={true}
                                    type="email"
                                    value={this.state.email}
                                    placeholder="Email Address"
                                    prefix={<Icon type="compose" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                                    onChange={(event) => this.updateState({email: event.target.value})}
                                />

                            </div>
                            <Row style={{ width: '30em', marginBottom: '1em'}}>
                                <Col xs={24} style={{
                                        textAlign: "right"
                                    }}>
                                    <a href="/login">Back to Login</a>
                                </Col>
                            </Row>
                            <div>
                                <Button size={'large'} type={'primary'} onClick={this.resetPassword}> Reset Password </Button>
                            </div>
                        </Row>
                    </Card>
                </div>
            </div>
        )
    }
}

export default ResetPassword