import API from '../../effects/API'
import {BASE_URL, SURVEY_TOP_REPORT_URL} from '../../environment/globals'
import {
    Button,
    Card,
    Col,
    Divider,
    Empty,
    Icon,
    Input,
    Pagination,
    Radio,
    Row,
    Select,
    Skeleton,
    Spin,
    Typography
} from 'antd'
import CustomComponent from '../../CustomComponent'
import React, {useEffect, useState} from 'react'

import RetryHomeRow from '../../ui_pieces/retry_home_row'

const {Option} = Select;
const {Text} = Typography;
const {Search} = Input;

class CategoryQuestions extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            questionSelected: props.questionSelected,
            isDiary: props.isDiary
        }
    }

    render() {
        return (
            <Row style={{ marginTop: '2em' }}>
                <Col>
                    {<Questions questionSelected={this.state.questionSelected} isDiary={this.state.isDiary}/>}
                </Col>
            </Row>
        )
    }
}

// class Categories extends CustomComponent {
//     constructor(props) {
//         super(props);
//         const [{isLoading, isError, error, data, forceUpdateSwitch}, setForceUpdateSwitch] =
//             useDataApi('http://jfie' + SURVEY_TOP_REPORT_URL, [{}, {}, {}, {}, {}])
//     }
//
//     render() {
//         return (
//             <Card bordered={false} bodyStyle={{ paddingBottom: 0 }}>
//                 <Select
//                     size={'large'}
//                     loadingg={true}
//                     allowClear={false}
//                     showSearch
//                     style={{ width: 200 }}
//                     placeholder="Select a person"
//                     optionFilterProp="children"
//                     // onChange={onChange}
//                     // onFocus={onFocus}
//                     // onBlur={onBlur}
//                     // onSearch={onSearch}
//                     filterOption={(input, option) =>
//           option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//         }
//                 >
//                     <Option value="jack">Jack</Option>
//                     <Option value="lucy">Lucy</Option>
//                     <Option value="tom">Tom</Option>
//                 </Select>
//                 <Divider style={{ marginBlock: '1em' }}/>
//             </Card>
//
//         )
//     }
// }

class Questions extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isError: false,
            error: '',
            data: null,
            isDiary: props.isDiary,
            filter: null,
            filteredData: null,
            questionURL: props.isDiary ? '/api/categories/' : '/api/biweeklies/',
            page: 1,
        };

        this.questionSelected = props.questionSelected;

        this.getQuestions = this.getQuestions.bind(this);
        this.filterQuestions = this.filterQuestions.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        this.getQuestions();
    }

    getQuestions()
    {
        this.updateState({
            isError: false
        });
        API.get(this.state.questionURL + sessionStorage.getItem('surveyId') + '/question?page=' + this.state.page).then(res => {
            this.updateState({
                data: res.data,
                isLoading: false
            }, this.filterQuestions)
        }).catch(err => {
            this.updateState({
                isError: true,
                error: err
            });
        });
    }

    filterQuestions()
    {
        // filter questions
        if (this.state.filter === '' || this.state.filter == null) {
            this.setState({filteredData: this.state.data})
        } else {
            this.updateState({
                filteredData: {data: []}
            });

            const tempFilterData = [];

            this.state.data.biweekly_questions.data.forEach(question => {
                if (question.short_form.toLowerCase().includes(this.state.filter.toLowerCase())) {
                    tempFilterData.push(question)
                }
            });
            this.updateState({
                filteredData: {biweekly_questions: {data: tempFilterData}}
            })
        }

        if (this.state.data && this.state.data.hasOwnProperty('biweekly_questions') && this.state.data.biweekly_questions.data.length && this.state.filter === null) {
            this.questionSelected({
                id: this.state.data.biweekly_questions.data[0].id,
                short_form: this.state.data.biweekly_questions.data[0].short_form,
                name: this.state.data.biweekly_questions.data[0].question,
                type: this.state.data.biweekly_questions.data[0].type,
                parent: this.state.data.biweekly_questions.data[0].parent
            })
        }
    }

    render() {
        let questionsData = null;
        try {
            questionsData = this.state.filteredData.hasOwnProperty('biweekly_questions') ?
                <Radio.Group defaultValue={this.state.filteredData.biweekly_questions.data[0].id} size="large">
                    {this.state.filteredData.biweekly_questions.data.map((question, index) => {
                        if (question.aggregate != "1") {
                            return (
                                <div key={question.id} style={{ marginTop: '.5em' }}>
                                    <Radio.Button style={{ overflow: 'visible', height: 'auto', width: '100%' }}
                                                  value={question.id}
                                                  question={question.question}

                                                  onChange={(event) => {
                              this.questionSelected({
                                id: question.id,
                                short_form: question.short_form,
                                name: question.question,
                                type: question.type,
                                parent: question.parent
                              })

                            }}> {question.short_form}</Radio.Button>
                                </div>
                            )
                        }
                    })}

                </Radio.Group> :

                <Row justify={'center'} type={'flex'}>
                    <Empty description={'No questions could be found for the survey'}/>
                    <RetryHomeRow
                        retryHandler={() => this.getQuestions()}
                        showHome={false}

                    />
                </Row>

        } catch (e) {

        }

        return (

            <Card bordered={false} bodyStyle={{ paddingTop: 0 }}>
                <Row>
                    <Search
                        placeholder="filter questions"
                        onChange={(e) => this.state.data && this.state.data.hasOwnProperty('biweekly_questions') && this.setState({filter: e.target.value}, this.filterQuestions)
          }
                    />
                </Row>
                <Row>
                    <div style={{ marginTop: '1em', marginBottom: '1em' }}>
                        <Text type={'secondary'} strong={true}> Select a question to visualize data</Text>
                    </div>

                    <Spin size={'large'} style={{ marginTop: '1em', marginBottom: '1em' }} spinning={this.state.isLoading}
                          indicator={<Icon type={'loading'} spin/>}
                    >
                        <Skeleton loading={this.state.isLoading} title={0} paragraph={{ rows: 4 }}>
                            {!this.state.isError && questionsData}
                        </Skeleton>

                    </Spin>

                    {this.state.isError &&
                    <Button type={'link'} icon={'reload'} onClick={() => this.getQuestions()}
                            title={'Reload questions'}> Reload Questions</Button>
                    }
                </Row>
                <Row>
                    <div style={{ marginTop: '2em' }}>
                        {
                            this.state.data && this.state.data.hasOwnProperty('biweekly_questions') && this.state.data.biweekly_questions.total > 0 &&
                            <Pagination size={'small'} defaultCurrent={1} current={this.state.data.biweekly_questions.current_page}
                                        total={this.state.data.biweekly_questions.total}
                                        pageSize={this.state.data.biweekly_questions.per_page}
                                        onChange={(page) => {
                          this.setState({page: page}, this.getQuestions)
                        }}/>
                        }
                    </div>
                </Row>


            </Card>)
    }

}

export default CategoryQuestions;