import React, {useEffect, useState} from 'react'
import {Button, Card, Icon, message, Input, Row, Col, Typography} from 'antd'
import useDataApi from '../../effects/fetcher'
import {BASE_URL, CLIENT_ID, CLIENT_SECRET, DELETE_COOKIES, LOGIN_URL} from '../../environment/globals'

const {Title} = Typography;
message.config({
    top: 10,
    duration: 1,
    maxCount: 1
});

function storeTokenCookie({data, email}) {
    let date = new Date();
    date.setTime(date.getTime() + (data['expires_in'] * 1000));
    let expires = '; expires=' + date.toGMTString();

    document.cookie = 'access_token=' + data['access_token'] + expires + '; path=/';
    document.cookie = 'email=' + email + expires + '; path=/'

}

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [{isLoading, isError, error, data, forceUpdateSwitch}, setForceUpdateSwitch, setURL, setRequestJSON] =
        useDataApi('', [], {}, 'POST');
    const [redirecting, setRedirecting] = useState(false);
    const [loginNow, setLoginNow] = useState(false);


    const login = () => {
        const hide = message.loading('Action in progress..', 1);

        const loginRequestData = {
            email: email,
            password: password,
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            grant_type: 'password'
        };

        setRequestJSON(loginRequestData);
        setURL(BASE_URL + LOGIN_URL);

        if (!isLoading) {
            if (!isError) {
                if (data.hasOwnProperty('access_token') && data['expires_in'] > 0) {
                    message.success('Successfully logged in');
                    storeTokenCookie({data, email});
                    setRedirecting(true)
                } else if (data.hasOwnProperty('message')) {
                    setLoginNow(false);
                    message.error('Email or Password is incorrect, please try again!', 1)
                }
            }
            // else if (error.hasOwnProperty('message')) {
            //   message.error(`Email or Password is incorrect, please try again!`, 1)
            // }
        }
    };


    useEffect(() => {

        DELETE_COOKIES()
    }, []);

    if (!isLoading) {
        if (!isError) {
            if (data.hasOwnProperty('access_token') && data['expires_in'] > 0) {
                message.success('Successfully logged in');
                storeTokenCookie({data, email});
                // setRedirecting(true);
                window.location.href = '/surveys'
            } else if (data.hasOwnProperty('message')) {
                setLoginNow(false);
                message.error('Email or Password is incorrect, please try again!', 1)
            }
        }
        // else if (error.hasOwnProperty('message')) {
        //   message.error(`Email or Password is incorrect, please try again!`, 1)
        // }
    }

    if (redirecting) {
        window.location.href = '/surveys'
    }
    return (
        <div>
            {!redirecting &&
            <div style={{ margin: '2em' }}>
                <Card
                    style={{
            padding: 0,
            borderRadius: '.625rem',
            boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)',
            margin: 'auto', marginTop: '.5em', width: '50vw',
            background: '#f5f6f !important'
          }}
                    className={'loginCard'}
                    bodyStyle={{ paddingTop: 0 }}
                    title={
            <Row type={'flex'}
                 style={{
                   flexDirection: 'column',
                   alignItems: 'center',

                   background: '#f5f6f'
                 }}>

              {/*<img src={'/logo_without_text.jpg'}*/}
                   {/*style={{*/}
                     {/*display: 'block',*/}
                     {/*margin: 'auto',*/}
                     {/*width: '100px',*/}
                     {/*height: '100px',*/}
                     {/*borderRadius: '4%',*/}
                     {/*// boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)',*/}
                     {/*objectFit: 'contain'*/}

                   {/*}}/>*/}

              <Title level={2}
                     style={{ textAlign: 'center' }}> LOGIN </Title>
            </Row>
          }
                >


                    <Row type={'flex'} style={{ flexDirection: 'column', alignItems: 'center' }}>

                        {/*<Title type={'secondary'} level={4}> Username </Title>*/}

                        <div style={{ width: '30em', marginBottom: '1em', marginTop: '1em' }}>
                            <Input
                                required={true}
                                placeholder="Enter your username"
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                                onChange={(event) => setEmail(event.target.value)}
                                // suffix={
                                //   <Tooltip title="Extra information">
                                //     <Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }}/>
                                //   </Tooltip>
                                // }
                            />

                        </div>

                        {/*<Title type={'secondary'} level={4}> Password </Title>*/}
                        <div style={{ width: '30em', marginBottom: '1em' }}>

                            <Input.Password
                                defaultValue={''}
                                required={true}
                                placeholder="Enter password"
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                                onChange={(event) => setPassword(event.target.value)}/>

                        </div>
                        <Row style={{ width: '30em', marginBottom: '1em'}}>
                            <Col xs={12}>
                                <a href="/reset-password">Forgot Password</a>
                            </Col>
                            <Col xs={12} style={{
                                        textAlign: "right"
                                    }}>
                                <a href="/request-access">Request Access</a>
                            </Col>
                        </Row>
                        <div>
                            <Button size={'large'} type={'primary'} onClick={login}> Login </Button>
                        </div>


                    </Row>
                </Card>

            </div>}

        </div>
    )
}

export default Login