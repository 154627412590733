import React from 'react'
import CustomComponent from '../../CustomComponent'
import {Card, Col, Divider, Empty, Icon, Row, Skeleton, Statistic, Typography} from 'antd'
import {BASE_URL, PERCENTAGE_COLORS, SURVEYS_SUMMARY_URL} from '../../environment/globals'

import API from '../../effects/API'

import '../assets/main.less'

import RetryHomeRow from '../../ui_pieces/retry_home_row'
import ErrorView from '../../ui_pieces/error_view'

const {Meta} = Card;
const {Title} = Typography;
const {Text} = Typography;


const surveyClicked = (surveyId, surveyName = '', isBiweeklySpecialsSelected = false, defaultBiweeklySpecialName = '') => {
    sessionStorage.setItem('surveyId', surveyId);
    sessionStorage.setItem('biweekly_specials', isBiweeklySpecialsSelected);
    sessionStorage.setItem('selectedBiweeklySpecial', defaultBiweeklySpecialName);
    sessionStorage.setItem('isDiary', false);
    sessionStorage.setItem('surveyName', surveyName);
    window.location.href = '/dashboard'
};

class SurveySummaryCard extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = props.survey;
        this.state.loading = props.loading;
        this.state.survey = props.survey;
    }

    render() {
        const percentage_color =
            this.state.total_respondents >= 10000
                ? PERCENTAGE_COLORS.high
                : this.state.total_respondents >= 1000
                    ? PERCENTAGE_COLORS.medium
                    : PERCENTAGE_COLORS.low;
        return (
            <Col xs={20} md={10} lg={6} className={'surveys mid-padded'}>


                <Card hoverable={!this.state.loading}
                      onClick={() => surveyClicked(this.state.survey.id, this.state.name)}>

                    <Skeleton loading={this.state.loading} active={true}>
                        <Divider>
                            <Meta
                                title={<h1 className="surveys text-center">{this.state.name}</h1>}
                                style={{ margin: 0 }}
                            />
                        </Divider>
                        <Row type="flex" justify="space-around">
                            <Col span={10}>
                                <Statistic title="Total Questions" value={this.state.total_questions}/>
                            </Col>
                            <Col span={10}>
                                <Statistic
                                    title="Respondents"
                                    value={this.state.total_respondents}

                                    valueStyle={{ color: percentage_color }}
                                    prefix={<Icon type="bar-chart"/>}

                                />
                            </Col>
                        </Row>
                    </Skeleton>
                </Card>


            </Col>
        );
    }
}

class Surveys extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: true,
            isError: false,
            error: ""
        };

        this.loadSurveys = this.loadSurveys.bind(this);
    }

    componentDidMount()
    {
        super.componentDidMount();
        this.loadSurveys();
    }

    loadSurveys() {
        this.updateState({
            isError: false
        });
        API.get(SURVEYS_SUMMARY_URL).then(res => {
            this.updateState({
                data: res.data,
                isLoading: false
            })
        }).catch(err => {
            this.updateState({
                isError: true,
                error: err
            });
        });
    }

    render() {

        let surveyReviews = null;

        // try to parse data and prepare a view consisting
        // of survey review cards
        try {
            surveyReviews = this.state.data.surveys.length ? (

                    this.state.data.surveys.map((survey, index) => {
                            return (
                                <SurveySummaryCard
                                    key={index}
                                    loading={this.state.isLoading}
                                    survey={survey}
                                />
                            )
                        }
                    )
                ) : (
                    <div>
                        <Empty description={'No data could be found'}/>
                        <RetryHomeRow
                            retryHandler={() => this.loadSurveys()}
                        />
                    </div>
                )
        } catch (e) {
        }

        return (
            <div className="surveys high-margin">
                <Row type={'flex'} justify={'center'} className={'surveys no-margin'}>
                    <Title className={''}>RISE Portal </Title>
                </Row>
                <Row type={'flex'} justify={'center'} className={'surveys no-margin'}>
                    <Title level={2} className={''}>
                        Browse Surveys{' '}
                    </Title>
                </Row>

                <Row type={'flex'} justify={'center'} style={{ marginBottom: '2em' }}>
                    <Text strong>
                        All the surveys under RISE portal with review. Choose one to see
                        detail report of the survey.{' '}
                    </Text>
                </Row>

                {!this.state.isError &&
                <Row justify={'center'} type={'flex'}>
                    {surveyReviews}
                </Row>
                }

                {
                    this.state.data.surveys &&

                    <Row justify={'center'} type={'flex'}>
                        {/*<Col xs={20} md={10} lg={6} className={'surveys mid-padded'}>*/}


                            {/*<Card hoverable={!this.state.isLoading}*/}
                                  {/*onClick={() => surveyClicked(this.state.data['first_biweekly_special'].id, this.state.data['first_biweekly_special'].name, true, this.state.data['first_biweekly_special'].name)}*/}
                                  {/*style={{*/}

                    {/*// boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)'*/}
                  {/*}}>*/}

                                {/*<Skeleton loading={this.state.isLoading} active={true}>*/}
                                    {/*<Divider>*/}
                                        {/*<Meta*/}
                                            {/*title={<h1 className="surveys text-center">Biweekly Specials</h1>}*/}
                                            {/*style={{ margin: 0 }}*/}
                                        {/*/>*/}
                                    {/*</Divider>*/}
                                    {/*<Row type="flex" justify="center">*/}

                                        {/*<Statistic title="Total BiWeekly Specials" valueStyle={{ textAlign: 'center' }}*/}
                                                   {/*value={this.state.data.biweekly_specials.total}/>*/}
                                    {/*</Row>*/}
                                {/*</Skeleton>*/}
                            {/*</Card>*/}

                        {/*</Col>*/}

                        <Col xs={20} md={10} lg={6} className={'surveys mid-padded'}>


                            <Card hoverable={!this.state.isLoading}
                                  onClick={
                                    () => {
                                      window.location.href = '/diary-categories'
                                    }
                                  }>
                                <Skeleton loading={this.state.isLoading} active={true}>
                                    <Divider>
                                        <Meta
                                            title={<h1 className="surveys text-center">Diaries</h1>}
                                            style={{ margin: 0 }}
                                        />
                                    </Divider>
                                    <Row type="flex" justify="center">

                                        <Statistic title="Total Categories" valueStyle={{ textAlign: 'center' }}
                                                   value={this.state.data.diaries.total}/>

                                    </Row>
                                </Skeleton>
                            </Card>

                        </Col>

                        <Col xs={20} md={10} lg={6} className={'surveys mid-padded'}>


                            <Card hoverable={!this.state.isLoading}
                                  onClick={
                                    () => {
                                      window.location.href = '/special-categories'
                                    }
                                  }>
                                <Skeleton loading={this.state.isLoading} active={true}>
                                    <Divider>
                                        <Meta
                                            title={<h1 className="surveys text-center">Biweekly Specials</h1>}
                                            style={{ margin: 0 }}
                                        />
                                    </Divider>
                                    <Row type="flex" justify="center">

                                        <Statistic title="Total Weeks" valueStyle={{ textAlign: 'center' }}
                                                   value={this.state.data.biweekly_specials}/>

                                    </Row>
                                </Skeleton>
                            </Card>

                        </Col>

                        
                        <Col xs={20} md={10} lg={6} className={'surveys mid-padded'}>


                            <Card hoverable={!this.state.isLoading}
                                  onClick={
                                    () => {
                                      window.location.href = '/case-study-categories'
                                    }
                                  }>
                                <Skeleton loading={this.state.isLoading} active={true}>
                                    <Divider>
                                        <Meta
                                            title={<h1 className="surveys text-center">Case Study</h1>}
                                            style={{ margin: 0 }}
                                        />
                                    </Divider>
                                    <Row type="flex" justify="center">

                                        <Statistic title="Total Categories" valueStyle={{ textAlign: 'center' }}
                                                   value={this.state.data.diaries.total}/>

                                    </Row>
                                </Skeleton>
                            </Card>

                        </Col>

                    </Row>

                }


                {this.state.isError && (
                    <Row type={'flex'} justify={'center'}>
                        <ErrorView
                            error={this.state.error}
                            retryHandler={() => this.loadSurveys()}
                        />
                    </Row>
                )}
            </div>
        )
    }
}

export default Surveys;
