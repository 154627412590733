import {Alert, Card, Spin, Typography} from 'antd'
import PieChart from 'highcharts-react-official'
import Highcharts from 'highcharts'
import React, {useEffect} from 'react'
import useDataApi from '../../effects/fetcher'
import {BASE_URL} from '../../environment/globals'
import HighchartsReact from 'highcharts-react-official'

require('highcharts/modules/exporting')(Highcharts);

const {Meta} = Card
const {Title} = Typography

function PointData({selectedQuestion, segmentationQuestion, isDiary}) {
    const questionURL = isDiary ? '/api/diaries' : '/api/biweeklies'

    const [{isLoading, isError, error, data, forceUpdateSwitch}, setForceUpdateSwitch, setURL] =
        useDataApi(BASE_URL + questionURL + '/get-point-data/' + selectedQuestion.id + (segmentationQuestion && segmentationQuestion > 0 ? "?segmentation_id=" + segmentationQuestion : ""), {data: []})

    useEffect(() => {
        setURL(BASE_URL + questionURL + '/get-point-data/' + selectedQuestion.id + (segmentationQuestion && segmentationQuestion > 0 ? "?segmentation_id=" + segmentationQuestion : ""))
    }, [questionURL, selectedQuestion, segmentationQuestion, setURL]);
    const options = {
        chart: {
            type: 'scatter',
            zoomType: 'xy',
            height: 500
        },
        credits: {
            enabled: false
        },
        title: {
            text: data.hasOwnProperty('categories') ? data['question'] : 'Respondent\'s response for the question'
        },
        subtitle: {
            text: ''
        },
        colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
            '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a', '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
            '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
            '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
            '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
            '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
            '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
            '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
            '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
            '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
            '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'],
        xAxis: {
            categories: data.hasOwnProperty('categories') ? data['categories'] : [],
            title: {
                enabled: true,
                text: data.hasOwnProperty('segmentation') ? data['segmentation'] : "Categories"
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true
        },
        yAxis: {
            title: {
                text: data.hasOwnProperty("yAxisTitle") ? data["yAxisTitle"] : 'Responses'
            }
        },
        legend: {
            enabled: false
        },
        tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            formatter: function(){
                return (data.hasOwnProperty("categories") ? "Category: " + data['categories'][Math.floor(this.x + 0.5)]: "Respondent " + this.x) + ", Response: " + this.y;
            },
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 5,
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                }
            }
        },
        series: [{
            name: data.hasOwnProperty('question') ? data['question'] : "Point Data",
            data: data.hasOwnProperty('report') ? data['report'] : []
        }]
    };
    const HC = () => <div>
        <HighchartsReact
            immutable={false}
            highcharts={Highcharts}
            options={options}
            // options={barChartOptions}

        />
    </div>;

    return (

        <Card bordered={true} color={'blue'} bodyStyle={{ background: 'white' }}

              style={{
            marginTop: '2em',
            padding: 0,
            borderRadius: '.625rem',
            boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)'
          }}
              title={<Meta

            title={<Title type={'secondary'} level={4} style={{ marginBottom: 0 }}> Point Data Report </Title>}
            description={'Point data report for the questions'}/>
          }>

            <Spin active="active" size={'large'} spinning={isLoading}>


                <Alert
                    message={selectedQuestion.id ? 'Question selected' : 'Select a question'}
                    type={selectedQuestion.id ? 'info' : 'warning'}
                    description={selectedQuestion.name || 'No statistical information to display for the question'}
                    showIcon
                />

                <div style={{ marginTop: '2em' }}>
                    {
                        data.hasOwnProperty('report') ?
                            <HC/> :
                            <Alert message={'Non-numeric Question Selected'} type={'error'}
                                   description={'Make sure the selected question is a numeric question'}/>
                    }

                </div>

            </Spin>


        </Card>
    )
}

export default PointData