import {Alert, Card, Select, Spin, Typography, Button} from 'antd'
import React, {useEffect, useState} from 'react'
import useDataApi from '../../effects/fetcher'
import PieChart from 'highcharts-react-official'
import HighchartsReact from 'highcharts-react-official'

import {
    BASE_URL,
    SURVEY_SEGMENTATION_FILTER_REPORT_URL,
    SURVEY_SEGMENTATION_REPORT_URL
} from '../../environment/globals'
import Highcharts from 'highcharts'

require('highcharts/modules/exporting.js')(Highcharts);
require('highcharts/modules/export-data.js')(Highcharts);


const {Meta} = Card;
const {Title, Text} = Typography;
const {Option} = Select;

function CaseStudyReport({
    selectedQuestion, selectedRespondent, questionSelected
}) {

    const [{isLoading, isError, error, data, forceUpdateSwitch}, setForceUpdateSwitch, setURL] =
        useDataApi(BASE_URL + SURVEY_SEGMENTATION_REPORT_URL, [{}, {}, {}, {}, {}], {}, 'POST');

    const questionType = 'repeat_question';

    const detailURL = '/api/repeat_questions';
    const caseStudyURL = '/api/case_study';

    const [{
        isLoading: subQuestionsLoading, isError: subQuestionsIsError,
        error: subQError, data: questionDetail, forceUpdateSwitch: subQforceUpdateSwitch
    }, subQsetForceUpdateSwitch, setsubQURL] =
        useDataApi(BASE_URL + detailURL + '/' + selectedQuestion.id + '/detail', [{}, {}, {}, {}, {}]);


    const [subQuestions, setSubQuestions] = useState([]);
    const [parentQuestion, setParentQuestion] = useState([]);


    useEffect(() => {
        setsubQURL(BASE_URL + detailURL + '/' + selectedQuestion.id + '/detail');
        if (selectedQuestion.type === 'Multiple') {
            if (questionDetail.hasOwnProperty(questionType)) {
                setParentQuestion(selectedQuestion)
                setSubQuestions(questionDetail[questionType].children)
            }
        } else if (questionDetail.hasOwnProperty(questionType) &&
            questionDetail[questionType].id === selectedQuestion.id && questionDetail[questionType].parent === 0) {
            setSubQuestions([])
        }

        if (selectedQuestion && selectedQuestion.id > 0 && selectedRespondent) {
            setURL(BASE_URL + caseStudyURL + '?question_id=' + selectedQuestion.id + "&respondent_id=" + selectedRespondent )
        }
    }, [selectedQuestion, selectedRespondent, setURL, data, setsubQURL, detailURL, questionDetail]);

    const subQuestionsData = subQuestions.length ?

        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Select loading={subQuestionsLoading} size={'large'}
                    defaultValue={'No question selected'}
                    placeholder={'Select a sub question'}
                    style={{ width: '20rem' }}
                    value={selectedQuestion.short_form}

                    onChange={(question) => {
                        question = JSON.parse(question);
                        questionSelected({
                            id: question.id,
                            short_form: question.short_form,
                            name: question.question,
                            type: question.type,
                            parent: question.parent
                        })
                }}>
                {

                    subQuestions.map((sub_question, index) => {
                        return (
                            <Option key={sub_question.id} value={JSON.stringify(sub_question)}
                                    title={sub_question.question}>
                                {sub_question.short_form}
                            </Option>
                        )
                    })
                }
            </Select>
        </div>
        : null;


    const pieChartOptions = {
        chart: {
            type: 'pie'
        },
        colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
            '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a', '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
            '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
            '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
            '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
            '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
            '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
            '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
            '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
            '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
            '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'],
        title: {
            text: 'Aggregate Questions Result'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        menuItems: [
            {
                textKey: 'print chart',
                onclick: () => this.print()
            },
            {
                separator: true
            }
        ],
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                },
                showInLegend: true
            }
        },
        series: [{
            name: 'Aggregate Questions Result',
            colorByPoint: true,
            data: data.hasOwnProperty('aggregate-report') ? data['aggregate-report'] : []
        }],
        credits: {
            enabled: false
        }
    };

    const barChartOptions = {
        chart: {
            type: 'column',
            height: 850,
            marginBottom: 150
        },
        title: {
            text: selectedQuestion.short_form
        },
        xAxis: {
            categories: data.hasOwnProperty('categories') ? data['categories'] : []
        },
        colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
            '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a', '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
            '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
            '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
            '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
            '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
            '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
            '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
            '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
            '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
            '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'],
        yAxis: {
            min: 0,
            title: {
                text: selectedQuestion.short_form || 'No question selected'
            }

        },
        legend: {
            layout: 'horizontal',
            align: 'left',
            verticalAlign: 'top',
            x: 10,
            y: 780,
            width: "100%",
            floating: true,
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,
            enabled: false
        },
        tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            shared: true,
            backgroundColor: '#FFF'
        },
        series: data.hasOwnProperty('series') ? data.series : data.hasOwnProperty('report') ? data.report : [],
        credits: {
            enabled: false
        },
        exporting: {
            showTable: false
        }
    };

    if (data.yAxis)
    {
        barChartOptions.yAxisValues = data.yAxis;
        barChartOptions.yAxis.labels = {
            formatter: function() {
                return barChartOptions.yAxisValues[this.value - 1];
            }
        };
    }
    else if (data.yaxis_special_symbol) {
        barChartOptions.yAxis.labels = {
            formatter: function() {
                return data.yaxis_special_symbol.replace("#NUM", "" + this.value);
            }
        };
    }
    else {
        barChartOptions.yAxis.labels = {
            formatter: function() {
                return this.value;
            }
        };
    }

    if (data.overall_max >= 0)
    {
        barChartOptions.yAxis.max = data.overall_max;
    } else if (data.yAxis)
    {
        barChartOptions.yAxis.max = data.yAxis.length;
    }

    if (data.overall_min >= 0)
    {
        barChartOptions.yAxis.min = data.overall_min;
    } else if (data.yAxis)
    {
        barChartOptions.yAxis.min = 0;
    }

    if (selectedQuestion.type == "Single")
    {
        barChartOptions.xAxis.offset = 0;
        //barChartOptions.yAxis.tickPixelInterval = 1;
    }
    else {
        barChartOptions.xAxis.offset = 0;
    }

    const HC = () => <div>
        <HighchartsReact
            style={{
            height: 600}
            }
            immutable={false}
            highcharts={Highcharts}
            options={barChartOptions}

        />
    </div>;


    return (


        <Card bordered={true} color={'blue'} bodyStyle={{ background: 'white' }}

              style={{
            padding: 0,
            borderRadius: '.625rem',
            boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)'
          }}
              title={<Meta

            title={<Title type={'secondary'} level={4} style={{ marginBottom: 0 }}> Case Study </Title>}
            description={'Visualize respondent\'s case study '}/>
          }>

            <Spin active="active" size={'large'} spinning={isLoading}>

                {subQuestionsData !== null ? subQuestionsData : <div/>}

                <Alert
                    message={selectedQuestion.id ? 'Question selected' : 'Select a question'}
                    type={selectedQuestion.id ? 'info' : 'warning'}
                    description={selectedQuestion.name || 'No statistical information to display'}
                    showIcon
                />
                {
                    data.hasOwnProperty('aggregate-report') ?
                        <PieChart options={pieChartOptions} highcharts={Highcharts}/> :
                        selectedQuestion.id > 0 && selectedQuestion.type !== 'Multiple'  && <HC/>
                }
            </Spin>


        </Card>
    )
}

export default CaseStudyReport