import { Button, Row } from 'antd'
import { Link } from '@reach/router'
import React from 'react'

function RetryHomeRow({ retryHandler, showHome = true, showRetry = true }) {
  return (
    <Row type={'flex'} justify={'center'} style={{ marginTop: '1em' }}>
      {
        showRetry && <Button type={'link'} icon={'reload'} onClick={retryHandler}>
          {' '}
          Retry
        </Button>
      }

      {
        showHome && <Link to="/">
          <Button type={'link'} icon={'home'}>
            Home
          </Button>
        </Link>
      }


    </Row>
  )
}

export default RetryHomeRow
