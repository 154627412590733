import {Card, Col, Divider, Empty, message, Row, Select, Skeleton, Statistic, Tabs, Typography} from 'antd'

import React, {useState} from 'react'
import CustomComponent from '../../CustomComponent'
import TopReports from './top_reports'
import CategoryQuestions from './category_questions'
import SegmentationFilters from './segmentations'
import CaseStudyReport from './case_study_report'
import './assets/main.css'
import AggregateQuestion from './aggregate_questions'
import API from '../../effects/API'
import {RESPONDENTS_URL} from '../../environment/globals'
import PointData from './point_data'
import { faKaaba } from '@fortawesome/free-solid-svg-icons'

const {Title, Text} = Typography;

const {TabPane} = Tabs;

const {Option} = Select;
const {Meta} = Card;

function getFormattedNumber(number) {
    const fixedNumber = Number.parseFloat(number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class QuickInfo extends CustomComponent {
    constructor(props) {
        super(props);
        const CATEGORY_COLORS = [
            '#F95700FF',
            '#00539CFF',
            '#2C5F2DFF',
            '#D85A7FFF',
            '#603F83FF',
            '#aa2761FF',
            '#2BAE66FF',
            '#9d9100FF',
            '#F2AA4CFF',
            '#2C5F2DFF',
            '#41a1f2FF',
            '#D85A7FFF',
            '#00539CFF',
            '#603F83FF',
            '#F2AA4CFF',
            '#2098a2FF',
            '#603F83FF',
            '#7b31cdFF'];
        this.state = {
            index: props.index,
            primary: props.info.primary,
            secondary: props.info.secondary,
            number: props.info.number,
            color: CATEGORY_COLORS[props.index % CATEGORY_COLORS.length],
            info: props.info
        };
    }
    
    componentWillUpdate(props, state)
    {
        if (this.state.number != props.info.number) {
            this.updateState({
                primary: props.info.primary, 
                secondary: props.info.secondary,
                number: props.info.number, 
                index: props.index,
                info: props.info
            });
        }
    }

    render() {
        return (
            <Col xs={24} md={12} lg={8} className={'quick-info surveys mid-padded'}>
                <Card style={{
                borderWidth: 0}} hoverable={true}
                      bodyStyle={{ border: "solid", borderColor: this.state.color, borderWidth: 2 }}>

                    <Skeleton loading={false} active={true}>
                        <Divider>
                            <Meta
                                title={<h2 className="surveys text-center" style={{ backgroundColor: this.state.color, 
                                    color: "white", padding:"3px", paddingLeft: "20px", 
                                    paddingRight:"20px", borderRadius: 25 }}>{this.state.primary}</h2>}
                                style={{ margin: 0, color: this.state.color }}
                                description={<span style={{ color: this.state.color }}></span>}
                            />
                        </Divider>
                        <Row type="flex" justify="space-around">

                            <Statistic valueStyle={{ textAlign: 'center', color: this.state.color }}
                                       title={<span style={{ textAlign: 'center', color: this.state.color }}> {this.state.secondary} </span>}
                                       value={this.state.number}/>

                        </Row>
                    </Skeleton>
                </Card>

            </Col>
        )
    }
}

class CaseStudy extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            filterVisible: true,
            selectedQuestion: {},
            segmentationQuestion: null,
            segmentationFilters: [],
            biweeklyFilters: [],
            aggregateData: {questions: [], computation: 1},
            isLoading: false,
            isError: false,
            categoryId: sessionStorage.getItem('surveyId'),
            isDiary: true,
            biweeklySpecial: sessionStorage.getItem('biweekly_specials') === 'true',
            error: '',
            data: null,
            respondents: [
                
            ],
            respondent: null
        };

        this.switchFilter = this.switchFilter.bind(this);
        this.questionSelected = this.questionSelected.bind(this);
        this.segmentationQuestionSelected = this.segmentationQuestionSelected.bind(this);
        this.filterSegmentations = this.filterSegmentations.bind(this);
        this.calculateAggregate = this.calculateAggregate.bind(this);
        this.getRespondents = this.getRespondents.bind(this);
    }

    componentDidMount()
    {
        super.componentDidMount();
        this.getRespondents();
    }

    componentWillUpdate(nextProps, nextState)
    {
        let call = false;
        if (JSON.stringify(this.state.biweeklyFilters) != JSON.stringify(nextState.biweeklyFilters)) {
            call = true;
        }
        if (JSON.stringify(this.state.segmentationFilters) != JSON.stringify(nextState.segmentationFilters)) {
            call = true;
        }
        if (call)
        {
            this.setState({call: call});
        }
    }

    componentDidUpdate() {
        if (this.state.call) {

            this.setState({call: false});
            this.getRespondents();
        }
    }

    getRespondents()
    {
        this.updateState({
            isError: false,
            isLoading: true
        });
        const data = {
            filters: this.state.segmentationFilters,
        };
        API.post(RESPONDENTS_URL, data).then(res => {
            const respondents = res.data;
            let respondent = null;
            if (respondents.length > 0)
            {
                respondent = respondents[0];
            }
            this.updateState({
                respondents: respondents,
                respondent: respondent,
                isLoading: false
            });
        }).catch(err => {
            this.updateState({
                isError: true,
                error: err
            });
        });
    }

    switchFilter = (value) => {
        this.updateState({
            filterVisible: value
        })
    };
    questionSelected = (question) => {
        this.updateState({
            selectedQuestion:question
        })
    };

    segmentationQuestionSelected = (question) => {
        this.updateState({
            segmentationQuestion: question
        })
    };

    filterSegmentations(filters, biweeklyFilters) {
        if (filters.length !== this.state.segmentationFilters.length) {
            this.updateState({
                segmentationFilters:filters
            });
        } else {
            filters.forEach((item, index) => {
                if (item !== this.state.segmentationFilters[index]) {

                    this.updateState({
                        segmentationFilters:filters
                    }, this.getRespondents);
                }
            });
        }
        this.updateState({
            biweeklyFilters:biweeklyFilters
        });
    };

    calculateAggregate(questions, computation) {
        if (questions.length >= 2) {

            let firstType = questions[0].type;
            let collision = false;
            questions.forEach(question => {
                if (question.type !== firstType) {
                    collision = true
                }
            });
            if (collision) {
                message.error('Make sure the selected questions have the same type!')
            } else {
                this.questionSelected({
                    name: `${questions.length} questions selected`,
                    short_form: `${questions.length} questions selected`,
                    id: -1,
                    parent: 0
                });
                this.updateState({
                    aggregateData: {
                        questions: questions.map(question => question.id),
                        computation: computation
                    }
                })
            }

        } else {
            message.warning('Make sure 2 more or numeric questions are selected!')
        }
    };

    render() {
        return (
            <div>
                <Row type={'flex'} justify={'space-between'} style={{ paddingTop: '2em', marginBottom: '2em' }}>
                    <Col span={12} style={{ paddingLeft: '2em', color: 'white' }}>

                        <Text level={4} secondary="true" style={{ color: '#e9e9e9' }}> Dashboard </Text>
                        <br/>
                        <Title level={2}
                               style={{
                   marginTop: 0,
                   color: '#e9e9e9'
                 }}> {sessionStorage.getItem('surveyName', '')} Overview </Title>

                    </Col>
                </Row>
                
                <Row type={'flex'} justify={'space-between'}>
                    <Col span={6}>
                        <Tabs defaultActiveKey={"1"}>
                            { this.state.categoryId && this.state.categoryId > 0 &&
                                <TabPane tab={<span>Direct Questions</span>} key={1}>
                                    <CategoryQuestions
                                        questionSelected={this.questionSelected}
                                        isDiary={this.state.isDiary}
                                    />
                                </TabPane>
                            }

                        </Tabs>
                    </Col>

                    <Col span={18}>
                        <Row type={'flex'} justify={'space-between'} style={{ margin: '3em' }}>
                            <Col span={this.state.filterVisible ? 16 : 22}>

                                <Tabs defaultActiveKey={"1"} type={'card'} animated={true}>
                                    <TabPane
                                        tab={<span style={{ fontSize: '1.1em', padding: '3em 4em', fontWeight: 'bold' }}>Case Study</span>}
                                        key={1}>
                                            <span style={{marginLeft: 20, marginRight: 20}}>Select Respondent</span>
                                            <Select size={'large'}
                                                defaultValue={this.state.respondents[0]}
                                                placeholder={'Select a respondent'}
                                                style={{ width: '20rem', marginleft: '1em', marginBottom: '1em'}}
                                                value={this.state.respondent}
                                                onChange={(respondent) => {
                                                            this.updateState({respondent: respondent});
                                                }}
                                            >
                                            {

                                                this.state.respondents.map((respondent, index) => {
                                                    return (
                                                        <Option key={index} value={respondent}
                                                                title={"Respondent " + respondent}>
                                                            {"Respondent " + respondent}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                        {<CaseStudyReport selectedQuestion={this.state.selectedQuestion}
                                                             selectedRespondent={this.state.respondent}
                                                             questionSelected={this.questionSelected}

                                        />}
                                    </TabPane>
                                </Tabs>

                            </Col>
                            <Col span={this.state.filterVisible ? 8 : 2}>

                                {<SegmentationFilters switchFilter={this.switchFilter}
                                                      segmentationQuestionSelected={this.segmentationQuestionSelected}
                                                      filterSegmentations={this.filterSegmentations}
                                                      isDiary={true}
                                                      isCaseStudy={true}
                                                      biweeklySpecial={false}

                                />}
                            </Col>

                        </Row>
                    </Col>

                </Row>
            </div>
        )
    }
}
export default CaseStudy;