import React from 'react'
import CustomComponent from '../CustomComponent'
import {Card, Col, Divider, Empty, Row, Skeleton, Statistic, Typography} from 'antd'
import {BASE_URL, GET_DIARIES_CATEGORIES} from '../environment/globals'

import API from '../effects/API'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faWallet} from '@fortawesome/free-solid-svg-icons'
import {faHandsHelping} from '@fortawesome/free-solid-svg-icons'
import {faShareSquare} from '@fortawesome/free-solid-svg-icons'
import './assets/main.less'

import RetryHomeRow from '../ui_pieces/retry_home_row'
import ErrorView from '../ui_pieces/error_view'


const {Meta} = Card;
const {Title} = Typography;
const {Text} = Typography;
const categoryIcons = [
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
    <FontAwesomeIcon icon={faShareSquare} size={'2x'}/>,
];

const categoryClicked = (categoryId, surveyName, isBiweeklySpecialsSelected = false, defaultBiweeklySpecialName = '') => {
    sessionStorage.setItem('surveyId', categoryId);
    sessionStorage.setItem('biweekly_specials', isBiweeklySpecialsSelected);
    sessionStorage.setItem('selectedBiweeklySpecial', defaultBiweeklySpecialName);
    sessionStorage.setItem('isDiary', true);
    sessionStorage.setItem('surveyName', 'Case Study / ' + surveyName);
    window.location.href = '/case-study';
};

class CaseStudyCategory extends CustomComponent {
    constructor(props) {
        super(props);
        // const CATEGORY_COLORS = [
        //     '#F95700FF',
        //     '#00539CFF',
        //     '#2C5F2DFF',
        //     '#D85A7FFF',
        //     '#603F83FF',
        //     '#aa2761FF',
        //     '#2BAE66FF',
        //     '#9d9100FF',
        //     '#F2AA4CFF',
        //     '#2C5F2DFF',
        //     '#41a1f2FF',
        //     '#D85A7FFF',
        //     '#00539CFF',
        //     '#603F83FF',
        //     '#F2AA4CFF',
        //     '#2098a2FF',
        //     '#603F83FF',
        //     '#7b31cdFF'];
        this.state = props.category;
        this.state.loading = props.loading;
        this.state.category = props.category;
    }

    render() {
        return (
            <Col xs={24} md={this.state.category.id < 0 ? 24 : 12} lg={this.state.category.id < 0 ? 12 : 8} className={'surveys mid-padded'}>
                <Card style={{
                borderWidth: 0
            }} hoverable={!this.state.loading}
                      onClick={() => categoryClicked(this.state.category.id, this.state.name)}
                      bodyStyle={{ border: "solid", borderColor: this.state.color, borderWidth: 2, borderRadius:100 }}>

                    <Skeleton loading={this.state.loading} active={true}>
                        <Divider>
                            <Meta
                                title={<h1 className="surveys text-center" style={{ backgroundColor: this.state.color, color: "white", padding:"3px", paddingLeft: "20px", paddingRight:"20px", borderRadius: 25 }}>{this.state.name}</h1>}
                                style={{ margin: 0, color: this.state.color }}
                                description={<span style={{ color: this.state.color }}>{categoryIcons[this.state.index]}</span>}

                            />
                        </Divider>
                        <Row type="flex" justify="space-around">

                            <Statistic valueStyle={{ textAlign: 'center', color: this.state.color }}
                                       title={<span style={{ textAlign: 'center', color: this.state.color }}> Total Questions </span>}
                                       value={this.state.total_questions}/>

                        </Row>
                    </Skeleton>
                </Card>

            </Col>
        )
    }
}

class CaseStudyCategories extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: true,
            isError: false,
            error: ""
        };

        this.reloadCategories = this.reloadCategories.bind(this);
    }

    componentDidMount()
    {
        super.componentDidMount();
        this.reloadCategories();
    }

    reloadCategories() {
        this.updateState({
            isError: false
        });
        API.get(GET_DIARIES_CATEGORIES).then(res => {
            this.updateState({
                data: res.data,
                isLoading: false
            })
        }).catch(err => {
            this.updateState({
                isError: true,
                error: err
            });
        });
    }

    render() {
        let surveyReviews = null;

        // try to parse data and prepare a view consisting
        // of survey review cards
        try {
            if (this.state.data.length) {
                surveyReviews = this.state.data.map((category, index) => {
                        category.index = index;
                        return (
                            <CaseStudyCategory
                                key={index}
                                loading={this.state.isLoading}
                                category={category}
                            />
                        )
                    }
                );
            }
            else {
                surveyReviews = (
                    <div>
                        <Empty description={'No data could be found'}/>
                        <RetryHomeRow
                            retryHandler={() => this.reloadCategories()}
                        />
                    </div>
                );
            }
        } catch (e) {
        }
        return (
            <div className="surveys high-margin">
                <Row type={'flex'} justify={'center'} className={'surveys no-margin'}>
                    <Title className={''}>Case Study Categories </Title>
                </Row>
                <Row type={'flex'} justify={'center'} className={'surveys no-margin'}>
                    <Title level={2} className={''}>
                        Select a Category
                    </Title>
                </Row>

                <Row type={'flex'} justify={'center'} style={{ marginBottom: '2em' }}>
                    <Text strong>
                        There are {this.state.data.length} diary categories in the system
                    </Text>
                </Row>

                {!this.state.isError &&
                <Row type={'flex'} justify={'center'}>
                    {surveyReviews}
                </Row>
                }

                {this.state.isError && (
                    <Row type={'flex'} justify={'center'}>
                        <ErrorView
                            error={this.state.error}
                            retryHandler={() => this.reloadCategories()}
                        />
                    </Row>
                )}
            </div>
        )
    }
}

export default CaseStudyCategories
