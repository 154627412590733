import useDataApi from '../../effects/fetcher';
import {AGGREGATE_COMPUTATION, BASE_URL} from '../../environment/globals'
import {Button, Card, Checkbox, Col, Empty, Icon, Input, Pagination, Radio, Row, Skeleton,Spin, Typography} from 'antd'
import RetryHomeRow from '../../ui_pieces/retry_home_row'
import React, {useState, useEffect} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDollarSign} from '@fortawesome/free-solid-svg-icons'
import {faCalculator} from '@fortawesome/free-solid-svg-icons'

const {Text} = Typography;
const { Search } = Input;

function AggregateQuestion({questionSelected, calculateAggregate, isDiary}) {


    const [computationType, setComputationType] = useState(AGGREGATE_COMPUTATION[Object.keys(AGGREGATE_COMPUTATION)[0]]);

    const aggregateQuestionURL = isDiary ? '/api/diaries' : '/api/biweeklies/' + sessionStorage.getItem('surveyId');

    const [{isLoading, isError, error, data, forceUpdateSwitch}, setForceUpdateSwitch] =
        useDataApi(BASE_URL + aggregateQuestionURL + '/get-aggregate-questions', []);
    const [selectedAggregateQuestions, setSelectedAggregateQuestions] = useState([]);

    let questionsData = null;
    let retryHandler = () => {
        setForceUpdateSwitch(!forceUpdateSwitch)
    };

    try {
        if (data.hasOwnProperty('questions')) {
            let options = [];

            data.questions.forEach((item, index) => {
                options.push({
                    value: item.id,

                    label: <span>
            <span style={{ margin: '1em' }}><FontAwesomeIcon
                icon={item.type === 'Money' ? faDollarSign : faCalculator}/></span>
            <span>{item.short_form} </span>
          </span>
                })
            });
            questionsData =
                <Row style={{ height: '600px', overflowY: 'scroll' }}>

                    <Checkbox.Group
                        options={options}
                        className={'custom-checkbox'}

                        onChange={(checkedQuestions) => {
              let selectedQuestions = [];
              checkedQuestions.forEach(checkedQuestionId => {
                selectedQuestions.push({
                  id: checkedQuestionId,
                  type: data.questions[data.questions.findIndex((element) => element.id === checkedQuestionId)].type
                })
              });
              setSelectedAggregateQuestions(selectedQuestions)
            }
            }

                    />
                </Row>

        } else {
            questionsData =
                <Empty description={'No numeric questions found in this survey'}/>
        }

        // // construct aggregate computations
        // <Radio.Group onChange={onChange} defaultValue="a"  >
        //
        // </Radio.Group>
        // aggregateComputations =  for(let aggregateComputation in AGGREGATE_COMPUTATION){
        //
        // }

    } catch (e) {
        questionsData = <
            RetryHomeRow
            showHome={false}
            retryHandler={retryHandler}
        />
    }
    return (
        <>
        <Row key={1} style={{ marginTop: '2em' }}>
            <Col>
                {<Questions questionSelected={questionSelected} isDiary={isDiary}/>}
            </Col>
        </Row>
        <Row key={2} type={'flex'} justify={'center'} style={{ margin: '2em 1em 1em 1em' }}>


            <Spin spinning={isLoading} size={'large'}
                  indicator={<Icon spin={isLoading} type={'loading'} title={'fetching data'}/>}>


                {/*<div style={{ marginBottom: '1em' }}>*/}
                    {/*<Text type={'secondary'} strong={true}> Select numeric questions to visualize results </Text>*/}
                {/*</div>*/}


                {/*<div style={{ marginBottom: '1em' }}>*/}
                    {/*{!isLoading && data.hasOwnProperty('questions') &&*/}
                    {/*<Button title={'Calculate'} type={'primary'} style={{ marginBottom: '.5em' }}*/}
                            {/*onClick={() => calculateAggregate(selectedAggregateQuestions, computationType)}>*/}
                        {/*Calculate</Button>}*/}
                {/*</div>*/}


                {/*<div style={{ marginBottom: '.5em' }}>*/}
                    {/*<Radio.Group buttonStyle={'outline'} onChange={(event) => setComputationType(event.target.value)}*/}
                                 {/*defaultValue={AGGREGATE_COMPUTATION[Object.keys(AGGREGATE_COMPUTATION)[0]]}>*/}
                        {/*{*/}
                            {/*Object.keys(AGGREGATE_COMPUTATION).map((key, index) =>*/}
                                {/*<Radio.Button key={index} value={AGGREGATE_COMPUTATION[key]}>{key}</Radio.Button>)*/}
                        {/*}*/}
                    {/*</Radio.Group>*/}
                {/*</div>*/}
                {/*{!isLoading && questionsData}*/}
            </Spin>
        </Row>
        </>
    )
}

function Questions({ questionSelected, isDiary }) {

    const questionURL = isDiary ? '/api/categories/' : '/api/biweeklies/';

    const [{ isLoading, isError, error, data, forceUpdateSwitch }, setForceUpdateSwitch, setURL] =
        useDataApi(BASE_URL + questionURL + sessionStorage.getItem('surveyId') + '/question', { data: [] });


    const [filteredData, setFilteredData] = useState(data);

    const [filter, setFilter] = useState(null);

    let questionsData = null;


    try {
        questionsData = filteredData.hasOwnProperty('biweekly_questions') ?
            <Radio.Group defaultValue={filteredData.biweekly_questions.data[0].id} size="large">
                {filteredData.biweekly_questions.data.map((question, index) => {
                    if (question.aggregate == "1")
                    {
                        return (
                            <div key={question.id} style={{ marginTop: '.5em' }}>
                                <Radio.Button style={{ overflow: 'visible', height: 'auto', width: '100%' }}
                                              value={question.id}
                                              question={question.question}
                                              onChange={(event) => {
                              questionSelected({
                                id: question.id,
                                short_form: question.short_form,
                                name: question.question,
                                type: question.type,
                                parent: question.parent
                              })

                            }}> {question.short_form}</Radio.Button>
                            </div>
                        )
                    }
                })}

            </Radio.Group> :

            <Row justify={'center'} type={'flex'}>
                <Empty description={'No questions could be found for the survey'}/>
                <RetryHomeRow
                    retryHandler={() => setForceUpdateSwitch(!forceUpdateSwitch)}
                    showHome={false}
                />
            </Row>

    } catch (e) {

    }


    useEffect(() => {


        // filter questions
        if (filter === '' || filter == null) {
            setFilteredData(data)
        } else {
            setFilteredData({ data: [] });

            const tempFilterData = [];

            data.biweekly_questions.data.forEach(question => {
                if (question.short_form.toLowerCase().includes(filter.toLowerCase())) {
                    tempFilterData.push(question)
                }
            });
            setFilteredData({ biweekly_questions: { data: tempFilterData } })
        }

        if (data.hasOwnProperty('biweekly_questions') && data.biweekly_questions.data.length && filter === null) {
            questionSelected({
                id: data.biweekly_questions.data[0].id,
                short_form: data.biweekly_questions.data[0].short_form,
                name: data.biweekly_questions.data[0].question,
                type: data.biweekly_questions.data[0].type,
                parent: data.biweekly_questions.data[0].parent
            })
        }

    }, [filter, data, questionSelected]);


    return (

        <Card bordered={false} bodyStyle={{ paddingTop: 0 }}>
            <Row>
                <div style={{ marginTop: '1em', marginBottom: '1em' }}>
                    <Text type={'secondary'} strong={true}> Select an aggregate question to visualize data</Text>
                </div>

                <Spin size={'large'} style={{ marginTop: '1em', marginBottom: '1em' }} spinning={isLoading}
                      indicator={<Icon type={'loading'} spin/>}
                >
                    <Skeleton loading={isLoading} title={0} paragraph={{ rows: 4 }}>
                        {!isError && questionsData}
                    </Skeleton>

                </Spin>

                {isError && <Button type={'link'} icon={'reload'} onClick={() => setForceUpdateSwitch(!forceUpdateSwitch)}
                                    title={'Reload questions'}> Reload Questions</Button>
                }
            </Row>
            <Row>
                <div style={{ marginTop: '2em' }}>
                    {
                        data.hasOwnProperty('biweekly_questions') && data.biweekly_questions.total > 0 &&
                        <Pagination size={'small'} defaultCurrent={1} current={data.biweekly_questions.current_page}
                                    total={data.biweekly_questions.total}
                                    pageSize={data.biweekly_questions.per_page}
                                    onChange={(page) => {
                          setURL(BASE_URL + questionURL + sessionStorage.getItem('surveyId') + '/question?page=' + page)
                        }}/>
                    }
                </div>
            </Row>
        </Card>)

}

export default AggregateQuestion